import { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { ContainerContext, FormContext, GlobalDndContext } from "../../contexts"
import { ShowHideContext, DialogModes } from '../../contexts'
import * as Structures from "../../structures"
import './styles.scss';
import { AddActions } from '../Container/addActions';
import SimpleStepper from '../../components/SimpleStepper';
import { TableOfContent } from '../../components/TableOfContent';
import { conditionTypes, dialogSizeTypes, dialogType, positionTypes, processTypes } from '../../utils/constants';
import { SortableContext } from '@dnd-kit/sortable';
import { DndSortable } from '../../components/DragAndDrop/sortable';
import { ErrorsList } from '../../screens/EditFormValues/errorsList';
import { isContainerHidden } from '../../utils/dialogDefinitions';
import { ElementFromType, getWrapperClassFromType } from '../Column';
import { designContexts } from '../../utils/constants';
import { useDefinitionManager } from '../../screens/Admin/DialogDesign/useDefinitionManager';
import _ from 'lodash';
import React from 'react';
import DigitalSigningBanner from '../../components/DigitalSigningBanner';
import SmartformStatusBanner from './smartformStatusBanner';
import { StepsList } from '../../screens/EditFormValues/stepsList';

export default function Dialog({ data,
  signatures,
  currentSignature,
  zoom,
  isMobileMode,
  scrollY,
  isPreviewMode = false,
  isEditMode = false,
  isPdfMode = false,
  isPreviewTemplateMode = false,
  isFormMode = false,
  activeDragFromSidbarType = null,
  previewModeAndshowHideLogicEnabled, // if true, will still enable show/hide logic when isPreviewMode is true
  ...props }) {
  const showAllContainers = !data || data.type === dialogType.normal || data.type === dialogType.standardWTable
  const isStandardWTable = data.type === dialogType.standardWTable;
  const isMultiStep = data.process === processTypes.multiStep
  const dialogRef = useRef();
  const formContext = useRef()
  formContext.current = useContext(FormContext);
  const [activeContainer, setActiveContainer] = useState(0);
  const [nextContainer, setNextContainer] = useState(activeContainer + 1);
  const [startContainer, setStartContainer] = useState(0);
  const [stopContainer, setStopContainer] = useState(data.containers.length);
  const [elementsToTableInfo, setElementsToTableInfo] = useState([])
  const firstRender = useRef(true);
  const { inputValues, dialogValues } = useContext(FormContext) || {};
  const dndContext = useContext(GlobalDndContext)
  const { isObjectTemplate } = useContext(ContainerContext) || {};
  const definitionManager = useDefinitionManager();

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  });

  useEffect(() => {
    let selectedRefs;
    if (isStandardWTable && isEditMode) {
      selectedRefs = dialogRef.current?.querySelectorAll("textarea.h1, textarea.h2, textarea.h3")
    } else {
      selectedRefs = dialogRef.current?.querySelectorAll("h1, h2, h3")
    }
    setElementsToTableInfo(Object.assign([], selectedRefs))
    return () => setElementsToTableInfo([])
  }, [data, inputValues])

  const spaceClass = showAllContainers && data?.space ? `space-y-${data.space}` : ''
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'

  let widthClass = 'max-w-screen-md'

  switch (data?.size) {
    case dialogSizeTypes.small:
      widthClass = 'max-w-screen-md'
      break;
    case dialogSizeTypes.medium:
      widthClass = 'max-w-screen-lg'
      break;
    case dialogSizeTypes.large:
      widthClass = 'max-w-screen-xl sm:pl-16 sm:pr-24'
      break;
    case 3:
      widthClass = 'maw-w-screen-sm'
      break;
    default:
      widthClass = 'max-w-screen-md'
      break;
  }
  function goToNextContainer(e) {
    let goToContainer = 0;

    if (data.type === dialogType.showAndHide) {
      goToContainer = nextContainer;
      setNextContainer(nextContainer + 1)
    }
    else
      goToContainer = activeContainer + 1;

    if (nextContainer >= startContainer && nextContainer <= stopContainer)
      setActiveContainer(goToContainer);

    // let goToContainer = 0;

    // if(data.type == 2) {
    //   goToContainer = nextContainer;
    //   setNextContainer(nextContainer + 1)
    // }
    // else
    //   goToContainer = activeContainer + 1;


    // setActiveContainer(goToContainer);
  }

  const getActiveContainer = () => {
    return data?.containers?.filter(m => !m.static && !isContainerHidden(m, inputValues))[activeContainer]
  }

  function goToPrevContainer(e) {
    let goToContainer = activeContainer - 1;

    if (goToContainer >= startContainer && goToContainer <= stopContainer) {
      setNextContainer(goToContainer + 1)
      setActiveContainer(goToContainer);
    }
  }

  const stepByStepStyles = () => {
    const isPositionLeft = data?.position === positionTypes.left
    let marginLeft = {
      0: '0px',
      1: '30px',
      2: '130px'
    }

    return showAllContainers ?
      {}
      :
      {
        backgroundColor: getActiveContainer()?.backgroundColor,
        padding: '24px',
        marginLeft: `${isEditMode && isPositionLeft ? marginLeft[data?.size] : marginLeft[dialogSizeTypes.small]} `
      }
  }

  const roundedCornerStepByStepClass = getActiveContainer()?.roundedCorners ? 'rounded-md' : ''

  const borderWidthStyle = !isPdfMode && data?.outsideStyles?.borderWidth ? `${data.outsideStyles?.borderWidth}` : 'inherit';
  const borderColorStyle = !isPdfMode && data?.outsideStyles?.borderWidth ? `${data.outsideStyles?.borderColor}` : 'inherit';
  const widthStyle = !isPdfMode && (data?.outsideStyles?.width || data?.outsideStyles?.width === '0') ? `${data.outsideStyles?.width}px` : '100%';


  const style = {
    backgroundColor: backgroundColorStyle,
    borderWidth: borderWidthStyle,
    borderColor: borderColorStyle,
    width: widthStyle
  }

  const steppers = useMemo(() => {
    let containers = data.containers.filter(m => !m.static)
    if (!isEditMode) {
      containers = containers.filter(m => !isContainerHidden(m, inputValues))
    }

    return containers.map(container => ({ name: container.name || "" }))
  }, [data.containers, inputValues, isEditMode])

  const showHideConditions = useMemo(() => {
    const logicControls = [];
    data.containers?.map(container => container.showLogicControl?.map(logicControl => logicControls.push(logicControl)))
    return logicControls
  }, [data.containers])

  const isHidden = (showLogicControl = []) => {
    if (!isEditMode) return logicControlsWithDependsVerification(showLogicControl);
  }

  const logicControlsWithDependsVerification = (logicControls) => {
    let clonedLogicControls = _.cloneDeep(logicControls)
    let isHidden = true;
    let dependsLogicControls = [];
    let dependsVerification = false

    clonedLogicControls?.sort((a, b) => a.conector - b.conector);

    let previousConditionResult;
    clonedLogicControls?.forEach((logicControl, index) => {
      const actualLogicControlVerif = logicControlsWithConectorVerification(logicControl, previousConditionResult)

      previousConditionResult = actualLogicControlVerif;
      let orderedDepenedsLogicControls = definitionManager.logicControlsByPropertyWithDepends(data, logicControl?.condition?.property)
      orderedDepenedsLogicControls.sort((a, b) => a.conector - b.conector);
      orderedDepenedsLogicControls.forEach(dependLocigControl => {
        //It goes through the dependencies, and adds one by one its verification.
        const dependLocigControlVerification = logicControlsWithConectorVerification(dependLocigControl, previousConditionResult)
        previousConditionResult = dependLocigControlVerification;
        dependsLogicControls.push(dependLocigControlVerification)
      })

      //If there is a false one among all of them, it is enough for it not to be shown.
      dependsVerification = dependsLogicControls?.indexOf(false) !== -1
      isHidden = dependsVerification || !actualLogicControlVerif
    })
    return clonedLogicControls?.length > 0 && isHidden;
  }

  const verifyConditionWithConditionType = (conditionType, value, match) => {
    switch (conditionType) {
      case conditionTypes.textMatch:
        return value === match;
      case conditionTypes.contain:
        return value?.includes(match);
      case conditionTypes.different:
        return value !== match;
      default:
        return false;
    }
  };

  const getElementValueWithGroupVerif = (condition, groupId) => {
    const valuesOfInputValues = Object.values(inputValues)
    const elementValue = groupId ? valuesOfInputValues.find(val => val.group === groupId && val.value) : inputValues?.[condition.property]

    return elementValue
  }

  const logicControlsWithConectorVerification = (logicControl, previousCondition) => {
    const condition = logicControl.condition;

    if (isPreviewMode && !previewModeAndshowHideLogicEnabled) {
      return true;
    }

    const groupedId = inputValues[condition.property]?.group
    const elementValue = getElementValueWithGroupVerif(condition, groupedId);

    let localPreviousCondition = previousCondition;
    if (logicControl.conector === 0)
      localPreviousCondition = verifyConditionWithConditionType(condition.conditionType, elementValue?.value, condition.match);
    else if (logicControl.conector === 1)
      localPreviousCondition = previousCondition && verifyConditionWithConditionType(condition.conditionType, elementValue?.value, condition.match);
    else {
      localPreviousCondition = previousCondition || verifyConditionWithConditionType(condition.conditionType, elementValue?.value, condition.match);
    }
    return localPreviousCondition;
  }

  const allContainers = useMemo(() => {
    let showContainers = data.containers.filter(m => !m.static && !isHidden(m.showLogicControl) && !isContainerHidden(m, inputValues));

    if(formContext.current?.handleHiddenContainersChange) {
      let hiddenContainers = data?.containers?.filter(m => !m.static && !showContainers.includes(m));
      formContext.current.handleHiddenContainersChange(hiddenContainers);
    }

    return showContainers
  }, [data.containers, inputValues])

  const formatCss = `@scope (#dialog-selector) to (.ignore-style-change) { ${data?.customCss} }`;

  return (
    <ShowHideContext.Provider
      value={{
        goToNextContainer: (e) => {
          goToNextContainer(e);
        },
        goToPrevContainer: (e) => {
          goToPrevContainer(e);
        },
        setFlowSelectorStartAndStop: (start, stop) => {
          setNextContainer(start);
          setStartContainer(start);
          setStopContainer(stop);
        },
        showHideConditions
      }}>

      <DialogModes.Provider
        value={{
          isPreviewMode,
          isEditMode,
          isPdfMode,
          isPreviewTemplateMode,
          isFormMode,
          firstRender: firstRender.current,
          isMobileMode,
          isStepByStepMode: !showAllContainers
        }
        }
      >
        <style type="text/css" centerpointselectedtheme="true">
          {
            `${formatCss}`
          }
        </style>
        <div id="dialog-selector">
          <div
            id="dialog-body"
            className={`sm:min-h-screen w-full print:my-0 ${backgroundColorStyle ? "print:min-h-screen" : ''}`}
            style={{ background: data?.outsideStyles?.backgroundColor }}
          >
            {
              isPdfMode &&
              <div className='bg-color-pdf' style={style}></div>
            }
            {
              data?.containers.filter(m => m.static).map((container, index) => {
                return (
                  container.static &&
                  <Structures.Container
                    showAllContainers={showAllContainers}
                    activeContainer={activeContainer}
                    totalContainers={allContainers.length - 1}
                    dialogData={data}
                    data={container.container ? container.container : container}
                    marginOverride={data.margin}
                    paddingOverride={data.padding}
                  />
                )
              })
            }
            <div className='max-w-screen-md mx-auto'>
              <DigitalSigningBanner />
              <SmartformStatusBanner />
            </div>
            <div className={`${isMobileMode ? "max-w-screen-xs" : widthClass} m-auto`}>
              {(isFormMode && isMultiStep && !dialogValues.isComplete) && <StepsList />}
              <ErrorsList />
            </div>
            <div
              className={`container z-10 ${"mx-auto"} p-5 md:p-10 flex flex-col print:max-w-full print:block ${isPreviewMode ? 'relative' : ''} ${isMobileMode ? "max-w-screen-xs" : widthClass} ${!isEditMode ? 'overflow-x-hidden' : ''}`}
              style={style}
            >
              {
                data?.containers.filter(m => m.static).map((container, index) => {
                  return (
                    container.static &&
                    <Structures.Container
                      showAllContainers={showAllContainers}
                      activeContainer={activeContainer}
                      totalContainers={data.containers.length - 1}
                      dialogData={data}
                      data={container.container ? container.container : container}
                      marginOverride={data.margin}
                      paddingOverride={data.padding}
                    />
                  )
                })
              }

              <div
                className={`sf-step-by-step-container ${roundedCornerStepByStepClass}`}
                style={stepByStepStyles()}
              >

                {
                  !showAllContainers &&
                  <>
                    <SimpleStepper steps={steppers} currentStep={activeContainer} position={data?.position} />
                  </>
                }

                {
                  isStandardWTable &&
                  <TableOfContent elementsToTableInfo={elementsToTableInfo} customStyles={data.standardWTableStyles} />
                }

                <div className={`flex flex-row relative`}>

                  <div ref={dialogRef} className={`print:table min-w-full flex flex-col ${spaceClass}`}>
                    {
                      !data.containers.length &&
                      <AddActions />
                    }
                    {
                      (!isEditMode || isMobileMode) &&
                      allContainers.map((container, i) =>
                        <div key={`container-${container.id}`}>
                          {
                            (showAllContainers || container.static || activeContainer === i || isPdfMode) &&
                            <Structures.Container
                              showAllContainers={showAllContainers}
                              activeContainer={activeContainer}
                              totalContainers={allContainers.length - 1}
                              dialogData={data}
                              data={container.container ? container.container : container}
                              marginOverride={data.margin}
                              paddingOverride={data.padding}
                            />
                          }
                        </div>
                      )
                    }
                    {
                      isEditMode && !isMobileMode &&
                      <>

                        <SortableContext
                          id={`container-sortable-context-${data.id}`}
                          items={data?.containers.filter(m => !m.static).map(c => `container-${c.id}`)}
                        >
                          {
                            <div className={`flex flex-col ${spaceClass}`}>
                              {data?.containers.filter(m => !m.static).map((container, index) => {
                                return (
                                  (showAllContainers || container.static || activeContainer === index) &&
                                  <DndSortable
                                    key={container.id}
                                    type="container"
                                    id={`container-${container.id}`}
                                    dragHandle={true}
                                    dragHandleTooltip='Drag container'
                                  >
                                    <Structures.Container
                                      showAllContainers={showAllContainers}
                                      activeContainer={activeContainer}
                                      totalContainers={data.containers.length - 1}
                                      dialogData={data}
                                      data={container.container ? container.container : container}
                                      marginOverride={data.margin}
                                      paddingOverride={data.padding}
                                    />
                                  </DndSortable>
                                )
                              })}
                            </div>

                          }
                        </SortableContext>
                        <SortableContext
                          id={`${activeDragFromSidbarType}-sortable-context-new-section`}
                          items={[`${activeDragFromSidbarType}-new-section-item`]}
                        >
                          {activeDragFromSidbarType && !isMobileMode &&
                            <DndSortable
                              type={activeDragFromSidbarType}
                              id={`${activeDragFromSidbarType}-new-section-item`}
                              dragHandle={true}
                              dragHandleTooltip='Drag container'
                            >
                              {!dndContext?.newSectionRecord && !isObjectTemplate &&
                                <div
                                  className='flex flex-1 justify-center items-center h-16 border-dashed border-2 rounded'
                                >
                                  New Section
                                </div>
                              }
                              {
                                dndContext?.newSectionRecord &&
                                <div className='flex flex-1 border-2 border-red-600'>
                                  {
                                    activeDragFromSidbarType === designContexts.element &&
                                    <div className='w-full'>
                                      <div className={getWrapperClassFromType(dndContext.newSectionRecord.type)}>
                                        <ElementFromType element={dndContext.newSectionRecord} dialogData={data} />
                                      </div>
                                    </div>
                                  }
                                  {
                                    activeDragFromSidbarType === designContexts.column &&
                                    <Structures.Column
                                      data={dndContext.newSectionRecord}
                                      dialogData={data}
                                    />
                                  }
                                  {
                                    activeDragFromSidbarType === designContexts.row &&
                                    <Structures.Row
                                      data={dndContext.newSectionRecord}
                                      dialogData={data}
                                    />
                                  }
                                </div>
                              }
                            </DndSortable>
                          }
                        </SortableContext>
                      </>
                    }
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </DialogModes.Provider>
    </ShowHideContext.Provider>
  );
}
