import { useState, useEffect } from 'react';
import { setProperty } from '../../utils/property';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { useSelector } from 'react-redux';


export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">

      <Property.Group
        title='Information'>
        <Property.Label
          label="Type"
          value="Rich Text"
        />

        <Property.Text
          onChange={handleOnChange}
          label={'Property'}
          name={'property'}
          value={data?.property}
        />

        <Property.Text
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Height'}
          name={'height'}
          value={data?.height}
        />

        <Property.Text
          onChange={(e) => handleOnChange(e, 'number')}
          label={'Width'}
          name={'width'}
          value={data?.width}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Enable for user input"}
          name={"richTextValues.enableUserInput"}
          value={data?.richTextValues?.enableUserInput}
        />

      </Property.Group>
    </div>
  )
}
