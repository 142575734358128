import { v4 as uuidv4 } from "uuid";

export const KYC4CompanyInformation = () => {
  return {
    name: "KYC - 4 - Company information",
    container: {
      id: uuidv4(),
      dialogObjectId: "67372de05b307343c4f40219",
      lockToDialogObject: false,
      space: null,
      backgroundColor: "",
      margin: "20px 0",
      padding: null,
      borderWidth: null,
      borderColor: null,
      roundedCorners: false,
      static: false,
      name: "The business",
      newPage: false,
      backgroundImage: null,
      backgroundSize: 0,
      backgroundRepeat: false,
      avoidBreakInside: false,
      rows: [
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 18,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "headlineNotification",
                  value: "Company details",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: "#f5f5f5",
          margin: "5px 0",
          padding: "10px",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "txtNotification",
                  value: "Type of business (Filled in by non-financial companies)",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "busniessTypePassive",
                  value: "passive",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Passive",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 27,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: "1",
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "radioButtonGroup-116b",
                  value: "active",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: "Active",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0 0 0 20px",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 18,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 400
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "inpBranch",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: 400,
                  height: null,
                  label: "Main activity/industry",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 18,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "txtNotificationMobile",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: 400,
                  height: null,
                  label: "Balance sheet total in kroner per accounting period",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 18,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "txtNotificationMobile-2",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: 400,
                  height: null,
                  label: "Turnover in kroner per accounting period",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 18,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "txtNotificationMobile-3",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: 400,
                  height: null,
                  label: "Number of employees",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0 0 0 20px",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 28,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: null,
                  text: null,
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "spacer-5b63",
                  value: null,
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: 10,
                  height: 30,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "test-2",
                  value: "Group structure ?",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                },
                {
                  id: uuidv4(),
                  type: 17,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "arial",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "arial",
                    size: 1,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#6b7281",
                    width: 0
                  },
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: "Yes - compilation is attached",
                  property: "checkbox-44c4",
                  value: null,
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: null,
                  width: null,
                  height: null,
                  label: " ",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: false,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: true,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 12,
                  inputType: 2,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "center",
                    fontFamily: "sans-serif",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "Upload",
                  value: null,
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#e5e7eb",
                  width: null,
                  height: null,
                  label: "Upload a file with group structure",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: "30px 0 0 0",
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: false,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        }
      ],
      showLogicControl: [],
      hasAutomaticTranslationOff: false,
      resourceId: null,
      readOnly: null
    }
  }
}