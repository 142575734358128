import { useState, useContext, useEffect } from 'react'
import { DialogModes, FormContext } from '../../contexts'
import 'react-day-picker/dist/style.css';
import './styles.scss'
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { timeHasValueSet } from "../../utils/elements"
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';
import { getDialogValueKey } from '../../utils/features';

export default function TimePicker({ data, ...props }) {
  const formContext = useContext(FormContext);
  const { isPdfMode } = useContext(DialogModes);
  const [value, setValue] = useState();
  const multiStep = useMultiStep(data);

  const classPrefix = "datePicker"
  const elementStyle = useElementStyle(data);
  const { color, fontSize } = elementStyle.textStyle(data?.text);

  const labelStyle = elementStyle.textStyle(data?.labelStyle, data?.text?.alignment);
  const labelClass = elementStyle.textClassName(data?.labelStyle);

  const { width, ...borderStyle } = elementStyle.borderAndWidthStyle();

  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''

  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'

  const inputWrapperStyle = {
    ...borderStyle,
    color,
    backgroundColor: backgroundColorStyle,
    width: '100%',
    maxWidth: width
  }

  const inputWrapperPdfStyle = {
    width: '100%',
    maxWidth: width,
    color
  }

  const textStyle = {
    fontSize,
    color
  }

  const val = formContext?.inputValues[getDialogValueKey(data)]?.value
    ? formContext?.inputValues[getDialogValueKey(data)]?.value
    : value

  const onTimeChange = (evt) => {
    const newValue = evt.target.value
    if (formContext) {
      formContext?.updateValue(getDialogValueKey(data), newValue)
    } else {
      setValue(newValue)
    }
    formContext?.reusablePropertyChanged(data.property, newValue)
  }

  const elementDesign = useElementDesign(data.id, data);

  useEffect(() => {
    if(val)
      formContext?.reusablePropertyChanged(data.property, val)
  }, [])
  
  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <div
        className={`${data?.labelTop ? `${elementDesign.elementsClasses()}` : `sm:grid sm:grid-cols-2 sm:gap-6 sm:items-start ${elementDesign.elementsClasses()}`}`}
        onClick={elementDesign.onClick}
        onBlur={elementDesign.handleBlur}
      >
        {
          data.label &&
          <label style={labelStyle} className={`block text-sm text-gray-700 sm:mt-px sm:pt-2 ${labelClass}`}>
            {elementDesign.translateTerm(data.label)}
            {elementDesign.requiredFieldIndicator(data.requiredField)}
            {elementDesign.translateHelpText(data)}
          </label>
        }
        {
          !data.label &&
          <label style={labelStyle} className={`block text-sm text-gray-700 sm:mt-px sm:pt-2`}>
            {elementDesign.requiredFieldIndicator(data.requiredField)}
            {elementDesign.translateHelpText(data)}
          </label>
        }
        {
          isPdfMode ?
          <div className='sm:mt-0' style={inputWrapperPdfStyle}>
            <label className='element block max-w-xl w-full' style={textStyle}>{val || "\u00A0"}</label>
          </div>
          :
          <div
            style={inputWrapperStyle}
            className={`sm:mt-0 ${roundedCornerClass} ${elementDesign.inputErrorClasses(data.requiredField, timeHasValueSet(val) ? 'value' : null)}`}
          >
            <input
              type="time"
              className={`w-full border-0 bg-transparent text-sm appearance-none outline-none h-10 ${elementDesign.readOnlyElementClasses()}`}
              value={val}
              onChange={onTimeChange}
              style={textStyle}
              disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
            />
          </div>
        }
      </div>
    </InlineActionbarElementWrapper>
  )
}
