import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getDialogDefinitionVersions, promoteDialogDefinitionVersion } from '../../../api/dialogDefinition';
import { useToastAction } from '../../../hooks/useToastAction';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { Rollback } from './rollback';
import { VersionPreview } from './preview';
import Modal from '../../../components/Modal';
import { Button } from '../../../components/Form/Button';
import { useModalHelper } from '../../../hooks/useModalHelper';
import { SmartFormEnvironmentBadge } from '../../../components/SmartFormEnvironmentBadge';
import { Checkbox } from '../../../components/Form/Checkbox';
import { smartFormEnvironmentNames, smartFormEnvironments } from '../../../utils/constants';
import { Label } from '../../../components/Form/Label';
import { Form, Formik } from 'formik';
import { useTableActions } from '@metaforcelabs/metaforce-core';
import { Table as PaginationTable } from "@metaforcelabs/metaforce-core";
import { Table } from '../../../components/Table';
import { useTableSearch } from '../../../hooks/useTableSearch';
import { Badge } from '../../../components/Badge';
import { CheckCircleIcon } from '@heroicons/react/solid';
import PromotePreview from './promotePreview';
import { featureFlags } from '../../../utils/features';

const environments = Object.keys(smartFormEnvironmentNames).map(k => ({ name: smartFormEnvironmentNames[k], value: k }));

const defaultFilter = environments.reduce((agg, curr) => {
  return { ...agg, [curr.value]: false }
}, {});

export default function DialogVersions() {
  //#region Modal data initialize
  const onModalClose = () => {
    setModalData(modalInitialState)
    modalHelper.close()
  }
  const modalInitialState = { title: "", size: "large", onModalClose, children: null }
  const [modalData, setModalData] = useState(modalInitialState);
  //#endregion


  const { dialogKey } = useParams();
  const [versions, setVersions] = useState([]);
  const [filteredVersions, setFilteredVersions] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(defaultFilter);
  const loadAction = useToastAction();
  const updateAction = useToastAction();
  const modalHelper = useModalHelper();

  const tableActions = useTableActions(filteredVersions, 20);

  useEffect(() => {
    load();
  }, [])

  const load = async () => {
    loadAction.execute(async () => {
      const versionsResult = await getDialogDefinitionVersions(dialogKey);
      setVersions(versionsResult);
      handleFilterChange(currentFilter, versionsResult);
    }, "Failed to load");
  }

  const onRollbackSuccess = async () => {
    await load()
  }

  if (loadAction.isExecuting || !versions) {
    return null
  }


  const onOpenRollbackModal = (elementId) => {
    setModalData({
      ...modalInitialState,
      children: <Rollback versionId={elementId} dialogDefinitionId={dialogKey} onSuccess={onRollbackSuccess} onFinally={onModalClose} />,
      title: "Rollback"
    })

    modalHelper.open()
  }

  const onOpenPreviewRollbackModal = (elementId) => {
    setModalData({
      ...modalInitialState,
      children: <VersionPreview versionId={elementId} />,
      title: "Preview"
    })

    modalHelper.open()
  }

  const handleOpenOpenPromoteModal = (version) => {
    const nextEnv = getNextEnv(version.environment);

    setModalData({
      ...modalInitialState,
      title: 'Promote version',
      children: <PromotePreview version={version} nextEnv={nextEnv} onCancel={modalHelper.close} onConfirm={handlePromoteVersion} />
    });
    modalHelper.open();
  }

  const handlePromoteVersion = async (version) => {
    await updateAction.executeAsync(async () => {
      const promoted = await promoteDialogDefinitionVersion(version.id);

      let updatedVersions = [...versions];
      promoted.isLatestEnvVersion = true;
      const idx = updatedVersions.findIndex(x => x.environment === promoted.environment && x.isLatestEnvVersion);
      if (idx > -1) {
        updatedVersions[idx].isLatestEnvVersion = false;
      }

      updatedVersions = [promoted, ...updatedVersions];
      setVersions(updatedVersions);
      handleFilterChange(currentFilter, updatedVersions);
    }, 'Promotion Failed');
    modalHelper.close();
  }

  const handleFilterChange = (filter, providedVersions = null) => {
    if (providedVersions == null) {
      providedVersions = versions;
    }
    let filtered = [...providedVersions.filter(x => {
      return x.isLatestEnvVersion ||
        (x.environment === smartFormEnvironments.development && filter[smartFormEnvironments.development]) ||
        (x.environment === smartFormEnvironments.test && filter[smartFormEnvironments.test]) ||
        (x.environment === smartFormEnvironments.preProd && filter[smartFormEnvironments.preProd]) ||
        (x.environment === smartFormEnvironments.production && filter[smartFormEnvironments.production])
    })];
    setCurrentFilter(prev => filter);
    setFilteredVersions(prev => filtered)

  }

  const getNextEnv = (currentEnv) => {
    const envs = Object.keys(smartFormEnvironmentNames).map(k => +k);
    return envs.filter(x => x > +currentEnv)[0];
  }

  const getNextEnvName = (currentEnv) => {
    // console.log(currentEnv)
    const nextEnv = getNextEnv(currentEnv);
    return smartFormEnvironmentNames[nextEnv];
  }

  const canPromote = (version) => {
    if (!featureFlags.smartformEnvironment) {
      return false;
    }
    const nextEnv = getNextEnv(version.environment);
    return version.isLatestEnvVersion && !!nextEnv;
  }

  return (
    <>
      <div className="pb-4  mt-10">
        <div className="pb-5 border-b border-gray-200 flex justify-between">
          <div className=''>
            <h1 className="text-2xl leading-6 font-medium  text-gray-900">Smartform Versions</h1>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              Administrate versions and environments
            </p>
          </div>
          <div className='self-end'>
            <VersionsFilter defaultFilter={currentFilter} onChange={handleFilterChange} />
          </div>
        </div>
      </div>
      {/* <div className="pb-4">
        <div className="pb-5 border-b border-gray-200 mb-6">
          
        </div>
      </div> */}
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
            <Table tableActions={tableActions} className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >

                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Updated
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Environment
                  </th>
                  <th scope="col" className="relative px-6 py-3" />
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tableActions.pageData.map((element) => (
                  <tr key={element.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{element.record.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stringToLocaleDateTimeString(element.record.createdDate)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{stringToLocaleDateTimeString(element.record.updatedDate)}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium"><SmartFormEnvironmentBadge environment={element.environment} isLatest={element.isLatestEnvVersion} /></td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className='inline-flex gap-2'>
                        {
                          featureFlags.smartformEnvironment && canPromote(element) && (
                            <Button
                              theme="text"
                              text={"Promote to " + getNextEnvName(element.environment)}
                              onClick={() => handleOpenOpenPromoteModal(element)}
                            />
                          )
                        }
                        <Button
                          theme="text"
                          text={"Preview"}
                          onClick={() => onOpenPreviewRollbackModal(element.id)}
                        />
                        {
                          element.isLatestEnvVersion && (<Button
                            theme="text"
                            text={"Rollback"}
                            onClick={() => onOpenRollbackModal(element.id)}
                          />)
                        }

                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <ModalWrapper data={modalData} isOpen={modalHelper.isOpen} />
    </>
  )
}

const VersionsFilter = ({ defaultFilter, onChange }) => {
  //console.log(defaultFilter);
  const submitRef = useRef(null);

  const onFormChange = () => {
    submitRef.current?.click();
  }

  return (
    <div>
      <Formik
        initialValues={{
          ...defaultFilter
        }}
        onSubmit={async (values) => {
          //console.log(values)
          onChange(values)
        }}
      >{({ values, handleChange, submitCount, ...props }) => (
        <Form onChange={onFormChange}>
          {/* <div className='mb-2'><Label label={"Environment"} /></div> */}
          <div className='flex space-x-4'>
            {environments.map(e => {
              return <Checkbox checked={values[e.value]} label={e.name} name={e.value} onChange={evt => {
                handleChange({ target: { name: e.value, value: evt } })
              }} />
            })}
          </div>
          <button ref={submitRef} type='submit' name={'submitfilter'} className='sr-only'></button>
        </Form>
      )}
      </Formik>
    </div>
  )
}

const ModalWrapper = ({ data, isOpen }) => {
  const { title, size, onModalClose, children } = data;

  return (
    <Modal
      size={size}
      title={title}
      isOpen={isOpen}
      onClose={onModalClose}
    >
      {children}
    </Modal>
  )
}
