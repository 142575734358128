import { useRef } from 'react'
import { Popover } from '@headlessui/react'
import useClickOutside from '../../hooks/useClickOutside'
import { MenuContext } from "./context"
import { Overlay } from '../Overlay'

export const PopoverMenu = ({ isOpen, onClose, children, leftPosition }) => {
  const popover = useRef();
  useClickOutside(popover, onClose);

  if (!isOpen) {
    return null
  }

  return (
    <MenuContext.Provider
      value={{
        onContextItemClicked: () => {
          onClose()
        }
      }}
    >
      <Overlay />
      <div ref={popover}>
        <Popover>
          <div>
            <Popover.Panel static className="absolute z-50" style={leftPosition ? {left: "-10.5rem" } : {}}>
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="ignore-style-change relative bg-white pt-1 pb-1" style={{ minWidth: '200px' }}>
                  {children}
                </div>
              </div>
            </Popover.Panel>
          </div>
        </Popover>
      </div>
    </MenuContext.Provider>
  );
}

