import { useContext, useState } from "react";
import { DesignActionbarContext } from "../../contexts";
import * as Icons from "@heroicons/react/outline"
import { designContexts, sidebarRightTabs } from "../../utils/constants";
import ActionbarButton from "./actionbarButton";
import { useSelector } from "react-redux";

export const InlineActionbar = ({ type, isOpen, close }) => {
  const [processingUndo, setProcessingUndo] = useState(false)
  const dialogDefinitionHistory = useSelector((state) => state.dialogDefinitions.history)

  const designActionbarContext = useContext(DesignActionbarContext);
  const {
    removeDisabled,
    canMoveLeft,
    canMoveRight,
    canMoveVertical,
    canDuplicate,
    setActiveTab,
    setCanMoveVertical,
    setCanMoveHorizontal,
    undo
  } = designActionbarContext || {}

  const handleRemove = () => {
    designActionbarContext.handleRemove()
  }

  const handleMoveLeft = () => {
    designActionbarContext.actions.moveLeft(designActionbarContext.dialogDefinition, designActionbarContext.data.id, designActionbarContext.data)
  }

  const handleMoveRight = () => {
    designActionbarContext.actions.moveRight(designActionbarContext.dialogDefinition, designActionbarContext.data.id, designActionbarContext.data)
  }

  const handleMoveUp = () => {
    designActionbarContext.actions.moveUp(designActionbarContext.dialogDefinition, designActionbarContext.data.id, designActionbarContext.data)
  }

  const handleMoveDown = () => {
    designActionbarContext.actions.moveDown(designActionbarContext.dialogDefinition, designActionbarContext.data.id, designActionbarContext.data)
  }

  const handleDuplicate = () => {
    designActionbarContext.actions.duplicate(designActionbarContext.dialogDefinition, designActionbarContext.data.id, designActionbarContext.data)
  }

  const enableUndo = () => {
    setProcessingUndo(false)
  }

  const handleAddClicked = (e) => {
    e.stopPropagation();
    setActiveTab(sidebarRightTabs.items)
    close();
  }

  const handleSelectLayoutType = (e, layoutType) => {
    e.stopPropagation();

    close();

    switch (layoutType) {
      case designContexts.container:
        setCanMoveHorizontal(false)
        setCanMoveVertical(true)
        break;
      case designContexts.row:
        setCanMoveHorizontal(false)
        setCanMoveVertical(true)
        break;
      case designContexts.column:
        setCanMoveHorizontal(true)
        setCanMoveVertical(false)
        break;
      case designContexts.element:
      default:
        setCanMoveVertical(true)
        setCanMoveHorizontal(true)
        break;
    }

    designActionbarContext.onSetSelectedLayoutType(layoutType, designActionbarContext.selectedLayoutType)
  }

  return (
    <>
      {
        designActionbarContext &&
        <div className={`${isOpen ? "block" : "hidden"} ${type} m-auto`} style={{ position: 'absolute', top: -40, zIndex: 9999 }}>
          <div className="flex bg-black w-fit m-auto borderitems-center">
            <ActionbarButton disabled={dialogDefinitionHistory.length === 0 || processingUndo} size="sm" icon={Icons.ReplyIcon} label="Undo" onClick={() => { setProcessingUndo(true); undo(enableUndo); }} processing={processingUndo} />
            <ActionbarButton hide={!designActionbarContext.canAdd()} onClick={handleAddClicked} icon={Icons.PlusCircleIcon} size="sm" label="Add" />
            <ActionbarButton hide={removeDisabled()} icon={Icons.TrashIcon} size="sm" onClick={handleRemove} label="Delete" />
            <ActionbarButton hide={!canMoveLeft()} spaceLeft={true} icon={Icons.ArrowSmLeftIcon} size="sm" onClick={handleMoveLeft} label="Move left" />
            <ActionbarButton hide={!canMoveRight()} icon={Icons.ArrowSmRightIcon} size="sm" onClick={handleMoveRight} label="Move right" />
            <ActionbarButton hide={!canMoveVertical()} icon={Icons.ArrowSmUpIcon} size="sm" onClick={handleMoveUp} label="Move up" />
            <ActionbarButton hide={!canMoveVertical()} icon={Icons.ArrowSmDownIcon} size="sm" onClick={handleMoveDown} label="Move down" />
            <ActionbarButton hide={!canDuplicate()} icon={Icons.DuplicateIcon} size="sm" onClick={handleDuplicate} label="Duplicate" />
            <ActionbarButton icon={Icons.TableIcon} spaceLeft={true} enabled={designActionbarContext.selectedLayoutType === designContexts.element} size="sm" onClick={(e) => handleSelectLayoutType(e, designContexts.element)} label="Element view" />
            <ActionbarButton icon={Icons.ViewListIcon} enabled={designActionbarContext.selectedLayoutType === designContexts.row} size="sm" onClick={(e) => handleSelectLayoutType(e, designContexts.row)} label="Row view" />
            <ActionbarButton icon={Icons.ViewBoardsIcon} enabled={designActionbarContext.selectedLayoutType === designContexts.column} size="sm" onClick={(e) => handleSelectLayoutType(e, designContexts.column)} label="Column view" />
          </div>
        </div>
      }

    </>
  );
}