import React, { useState } from 'react'
import { Button } from '../Form/Button';
import { CogIcon, DotsHorizontalIcon, EyeIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/outline';
import MenuContextList from '../MenuContextList';
import { action } from 'mobx';
import { ResourceModal } from '../../screens/Admin/Templates/Resources/resourceModal';
import { useResourcesHelper } from '../../hooks/useResourcesHelpers';
import { useToastAction } from '../../hooks/useToastAction';
import { createResource } from '../../api/resources';
import { resourceCategories } from '../../utils/constants';

export const ResourceSelect = ({ onChange, label, name, options, value,resourceCategories = [], optionalPropSet = null, defaultSelected = '', onResourcesChanged, showContextMenu = true}) => {
    const [localValue, setLocalValue] = useState(value || defaultSelected);
    const [showNewModal, setShowNewModal] = useState(false);
    const resourcesHelper = useResourcesHelper();
    const addAction = useToastAction();
    
    const handleOnChange = (e, type) => {
        const option = options.find(x => x.value === e.target.value)
        setLocalValue(option);
        const events = [e];

        if (optionalPropSet) {
            const optionalValue = option ? option[optionalPropSet.optionsTarget] : null
            events.push({ target: { name: optionalPropSet.name, value: optionalValue } });
        }
        onChange(events, type)
    }

    const isUrlValue = () => {
        return !!value && value?.startsWith('http');
    }

    const handleAddResource = () => {
        setShowNewModal(true);
    }

    const actions = [
        {
            onClick: handleAddResource,
            name: "Add",
            icon: PlusCircleIcon,
        }
    ];
    if (isUrlValue()) {
        actions.push({
            name: "View",
            href: value,
            external: true,
            icon: EyeIcon,
        })
    }
    actions.push({
        name: "All Resources",
        href: "/admin/templates/resources",
        icon: CogIcon,
    });

    const handleSaveNew = async (values, onComplete) => {
        await addAction.executeAsync(async () => {
            const request = await resourcesHelper.createAddResourceRequestAsync(values)
            const addedResource = await createResource(request);
            handleOnChange({ target: { value: addedResource.assetUrl, name: name } }, null)
            onResourcesChanged();
            setShowNewModal(false);
        })
        onComplete();
    }

    return (
        <>
            <div className='flex w-full px-4 sm:px-6 pt-3 space-x-2'>
                <div className={` flex-1 flex flex-col justify-between`}>
                    <span className="flex-grow flex flex-col" id="availability-label">
                        <span className="text-sm font-medium text-gray-500">{label}</span>
                    </span>
                    <select
                        name={name}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm rounded-md"
                        onChange={(e) => handleOnChange(e)}
                        value={value}
                    >
                        {
                            options.map((option, index) => {
                                return (<option key={`${option.name}${option.value}`} value={option.value}>{option.name}</option>)
                            })
                        }
                    </select>


                </div>
                { 
                    showContextMenu &&
                    <div className='flex mb-3 items-end flex-none'>
                        <MenuContextList
                            element={localValue || {}}
                            deleteType={"Resource"}
                            actions={[...actions]}
                        />
                    </div>
                }
            </div>

            <ResourceModal isOpen={showNewModal} setOpen={setShowNewModal} onSubmit={handleSaveNew} categoryLimits={resourceCategories} />
        </>
    );
}
