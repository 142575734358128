import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { getDialogDefinition } from '../../api/dialogDefinition';
import { getDialogValues } from '../../api/dialogValues';
import { FormContext } from '../../contexts';
import * as Structures from "../../structures";
import moment from 'moment';
import { valuesModelToKeyValuePair } from '../../utils/dialogValues';
import './styles.scss';
import GeneratePdfDialog from '../../components/GeneratePdfDialog';
import { generatePdf } from '../../api/dialogValues';
import SendingPdfToViewPointDialog from './sendingPdfToViewPointDialog';
import { Select } from '../../components/Form/Select';
import { useTranslations } from './useTranslations';
import { FileText } from 'lucide-react';

/**
 * Simplified version of EditForm in index.js.
 * Displays read-only version of the entire form. Calls admin api endpoints
 * which bypasses the simplified JWT authentication that is used on the public endpoints.
 */
export const AdminReadOnlyForm = () => {
  const { dialogKey, valuesKey } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [dialogValues, setDialogValues] = useState();
  const [showGeneratePdf, setShowGeneratePdf] = useState(false);
  const [showSendingPdfToViewPoint, setShowSendingPdfToViewPoint] = useState(false);
  const [dialogDefinition, setDialogDefinition] = useState();
  const [dialogFormValues, setDialogFormValues] = useState();
  const [saveMessage, setSaveMessage] = useState();
  const [reusableProperties, setReusableProperties] = useState({});
  const formRef = useRef();

  const translations = useTranslations(dialogKey)

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const [definition, dialogValues] = await Promise.all([
      getDialogDefinition(dialogKey),
      getDialogValues(valuesKey),
    ]);

    if (dialogValues.updatedDate) {
      const message = dialogValues.isComplete ? "Completed" : "Last updated";
      setSaveMessage(`${message} ${moment(dialogValues.updatedDate).fromNow()}`)
    }

    setDialogFormValues(valuesModelToKeyValuePair(dialogValues));
    setDialogDefinition(definition);
    setDialogValues(dialogValues);
    setReusableProperties({ ...reusableProperties, ...dialogValues.company })
    setIsLoading(false);
  }

  const onClickPreviewButton = async () => {
    setShowGeneratePdf(true);
    await generatePdf(dialogKey, valuesKey);
    setShowGeneratePdf(false);
  }

  const handleReusablePropertyChanged = (key, value) => {
    setReusableProperties(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  if (isLoading || translations.isLoading) {
    return null;
  }

  return (
    <FormContext.Provider
      value={{
        reusablePropertyChanged: handleReusablePropertyChanged,
        reusableProperties: reusableProperties,
        inputValues: dialogFormValues,
        dialogValues: dialogValues,
        processStep: {},
        isFormEnabled: false,
        isAborted: false,
        dialogDefinitionId: dialogKey,
        stepContainerDefinitionIds: [],
        currentStepElements: [],
        isFormSubmitting: false,
        updateValue: (key, value) => {

        },
        updateByPropertyNameValue: (propertyName, value) => {

        },
        uploadFile: (key, file) => {

        },
        deleteFile: (evt, key, id) => {
          evt.preventDefault();
        },
        resetForm: () => { },
        formRef: formRef,
        translateTerm: (element, fallbackText, propertyName) => {
          return translations.translateTerm(element, fallbackText, propertyName)
        },
        translateOption: (element, optionId, fallbackText, propertyName) => {
          return translations.translateOption(element, optionId, fallbackText, propertyName)
        },
        translateContainer: (element, fallbackText, propertyName) => {
          return translations.translateContainer(element, fallbackText, propertyName)
        },
        translateHelpText: (element, fallbackText) => {
          return translations.translateHelpText(element, fallbackText)
        },
        translateCustomErrorMessage: (element, isRequired) => {
          return translations.translateCustomErrorMessage(element, isRequired)
        },
        translateRow: (element, rowIndex, columnIndex, fallbackText) => {
          return translations.translateCustomErrorMessage(element, rowIndex, columnIndex, fallbackText)
        },
        translateColumnsSmartTable: (element, columnId, fallbackText) => {
          return translations.translateColumnsSmartTable(element, columnId, fallbackText);
        },
        translateOptionForSmartTable: (element, columnId, optionIndex, fallbackText) => {
          return translations.translateOptionForSmartTable(element, columnId, optionIndex, fallbackText)
        },
        translateRichText: (element, fallbackText) => {
          return translations.translateRichText(element, fallbackText)
        },
        addOrRemoveErrorInput: ({ id, property, field }, action = "add") => { },
        translateMatrixOption: (element, optionId, fallbackText) => {
          return translations.translateMatrixOption(element, optionId, fallbackText)
        },
        translateMatrixQuestion: (element, questionId, fallbackText) => {
          return translations.translateMatrixQuestion(element, questionId, fallbackText)
        },
        formErrors: []
      }}
    >
      <div className="flex justify-between px-5 py-1 print:p-0" style={{ backgroundColor: dialogDefinition.toolbarColor }}>
        <div className="flex items-center">
          <span className={'print:hidden'}>
            {saveMessage}
          </span>
        </div>

        <div className='flex items-center'>
          {
            translations.definitionLanguageOptions?.length > 0 &&
            <div className="mr-4">
              <Select
                options={translations.definitionLanguageOptions}
                onChange={translations.onLanguageChange}
              />
            </div>
          }


          <FileText
            onClick={onClickPreviewButton}
            className="print:hidden cursor-pointer h-6 w-6"
            title="Generate preview"
          />
        </div>

      </div>

      <form ref={formRef}>
        <Structures.Dialog data={dialogDefinition} isPreviewMode={true} previewModeAndshowHideLogicEnabled={true}  />
      </form>


      <span id='ready-to-print' />

      <GeneratePdfDialog open={showGeneratePdf} setOpen={setShowGeneratePdf} />
      <SendingPdfToViewPointDialog open={showSendingPdfToViewPoint} setOpen={setShowSendingPdfToViewPoint} />

    </FormContext.Provider>
  )
}
