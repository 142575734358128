import React, { useEffect, useContext, Fragment } from 'react';
import { Link, useNavigate } from "react-router-dom";
import * as _ from 'lodash';
import { Menu, Disclosure, Transition, Popover } from "@headlessui/react";
import {
  XIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import { useReactOidc } from '@axa-fr/react-oidc-context';

import { ViewGridIcon } from '@heroicons/react/outline';
import { config } from '../../utils/config';
import { setAuthHeader } from '../../api/api'
import { getCustomerDetails } from '../../api/customer';
import { Home, List } from "lucide-react"
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ErrorLayout({children}) {
    const navigate = useNavigate();
    
    const navigation = [
      { icon: <Home className="w-4 h-4" />, name: 'Dashboard', route: `/admin`, featureEnabled: true },
      { icon: <List className="w-4 h-4" />, name: 'Smartforms', route: `/admin/smartforms`, featureEnabled: true },
    ]
  
    const userNavigation = [
      { name: "Your Profile", href: "#" },
      { name: "Settings", href: "#" },
    ];
  
  
  
    const validateLicense = async () => {
      const customerDetails = await getCustomerDetails()
      const hasLicense = customerDetails?.activeProducts?.find(a => a.id === config.productLicense)
      if (hasLicense) {
        navigate('/'); // redirects to root to prevent site from hanging on a blank screen
      }
      else {
        navigate('/missing-license');
      }
    }
  
    const GridIconSendBaseUrl = () => (
      <a href={config.centerpointUiBaseUrl} rel="noreferrer" target="_blank" className={""}>
        <ViewGridIcon className="h-8 w-auto text-gray-800 hover:text-gray-600 cursor-pointer" />
      </a>
    )
  
    return (
      <div className="relative min-h-screen flex flex-col bg-white">
        {/*//#region START NEW NAV */}
        <header className="sticky top-0 z-10 w-full bg-white shadow">
          <div className="max-w-screen-3xl mx-auto px-2 sm:px-4 lg:px-8">
            <Popover className="flex justify-between h-16">
              <div className="flex px-2 lg:px-0">
                <div className="flex-shrink-0 flex items-center">
                  <GridIconSendBaseUrl />
                </div>
                <nav aria-label="Global" className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                  {navigation.map((item) => (
                     <Link
                        key={item.name}
                        to={"#"}
                        className={`${item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900'} block rounded-md py-1 px-3 text-sm font-medium `}>
                        {item.name}
                      </Link>
                  ))}
                </nav>
              </div>
              <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
              </div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Transition.Root as={Fragment}>
                <div className="lg:hidden">
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Overlay className="z-20 fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
                  </Transition.Child>
  
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                    >
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                        <div className="pt-3 pb-2">
                          <div className="flex items-center justify-between px-4">
                            <GridIconSendBaseUrl />
                            <div className="-mr-2">
                              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                <span className="sr-only">Close menu</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </Popover.Button>
                            </div>
                          </div>
                          <div className="mt-3 px-2 space-y-1">
                            {navigation.map((item) => (
                              !item.children ? (<Link
                                key={item.name}
                                to={""}
                                className={`${item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900'} block rounded-md py-1 px-3 text-sm font-medium `}
                              >
                                {item.name}
                              </Link>
                              ) : (
                                <Disclosure as="div" key={item.name} className="space-y-1" defaultOpen={item.current}>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        className={"text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 w-full flex items-center px-3 pr-1 py-2 text-left  rounded-md focus:outline-none cursor-pointer"
                                        }
                                      >
                                        <span className="flex-1 ">{item.name}</span>
                                        <svg
                                          className={`${open
                                            ? "text-gray-400 rotate-90"
                                            : "text-gray-300"} "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"`}
                                          viewBox="0 0 20 20"
                                          aria-hidden="true"
                                        >
                                          <path
                                            d="M6 6L14 10L6 14V6Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </Disclosure.Button>
                                      <Disclosure.Panel className="space-y-1">
                                        {item.children.map((subItem) => (
                                          <Link
                                            key={subItem.name}
                                            to={""}
                                            className={`${subItem.current ? "bg-gray-100 text-gray-900" :
                                              "text-gray-900 hover:bg-gray-50 hover:text-gray-900"} block rounded-md py-1 px-3 text-sm font-medium `}
                                          >
                                            {subItem.name}
                                          </Link>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              )
                            ))}
                          </div>
                        </div>
                        <div className="pt-4 pb-2">
                    {/*       <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                              {oidcRoutesContext.userProfile?.userProfileImage ?
                                (<img className="h-10 w-10 rounded-full"
                                  src={oidcRoutesContext.userProfile?.userProfileImage}
                                  alt="" />)
                                : (<UserIcon className="h-6 w-6" aria-hidden="true" />)}
                            </div>
                            <div className="ml-3">
                              <div className="text-base font-medium text-gray-800">{oidcRoutesContext.customerInfo?.name}</div>
                              <div className="text-sm font-medium text-gray-500">{oidcRoutesContext.customerInfo?.orgNumber}</div>
                            </div>
                          </div> */}
                          <div className="mt-3 px-2 space-y-1">
                            {userNavigation.map((item) => (
                              <Link
                                key={item.name}
                                to={""}
                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                              >
                                {item.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
  
                {/* Profile dropdown */}
                <Menu as="div" className="ml-4 relative flex-shrink-0">
              {/*     <div>
                    <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      <span className="sr-only">Open user menu</span>
                      {oidcRoutesContext.userProfile?.userProfileImage ?
                        <img className="h-8 w-8 rounded-full"
                          src={oidcRoutesContext.userProfile?.userProfileImage}
                          alt="" />
                        : (<UserIcon className="h-6 w-6" aria-hidden="true" />)}
  
                    </Menu.Button>
                  </div> */}
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={e => { item.onClick?.call() }}
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </Popover>
          </div>
        </header>
        {/*//#region END NEW NAV */}
  
        <main className="main-container bg-gray-50 flex-1 z-0 focus:outline-none">
          <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col pb-10">
              <div className="max-w-screen-3xl">
              <p className="text-2xl font-bold mt-6">{children}</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }