import { v4 as uuidv4 } from "uuid";

export const KYC2ForeignCompany = () => {
  return {
    name: "KYC - 2 Foreign Company",
    container: {
      id: uuidv4(),
      dialogObjectId: "67372f745b307343c4f49a9e",
      lockToDialogObject: false,
      space: null,
      backgroundColor: "",
      margin: "20px 0",
      padding: null,
      borderWidth: null,
      borderColor: null,
      roundedCorners: false,
      static: false,
      name: "Foreign organization number",
      newPage: false,
      backgroundImage: null,
      backgroundSize: 0,
      backgroundRepeat: false,
      avoidBreakInside: false,
      rows: [
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 0,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 18,
                    bold: true,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "KYC2-title",
                  value: "Foreign organization number",
                  placeholder: null,
                  borderWidth: null,
                  borderColor: null,
                  width: null,
                  height: null,
                  label: null,
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: null,
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: "#f5f5f5",
          margin: "5px 0",
          padding: "10px",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 2,
                  inputType: 0,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "sans-serif",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: {
                    value: "none",
                    type: 0
                  },
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "vatnumber-KYC2",
                  value: "New input text line",
                  placeholder: null,
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: null,
                  height: null,
                  label: "LEI number (Legal Entity Identifier)",
                  labelSetManually: false,
                  helpText: null,
                  leadingText: null,
                  trailingText: null,
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: "0 20px 0 0",
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            },
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 5,
                  inputType: 1,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "arial",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "arial",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "datePicker-5aa0-KYC2",
                  value: null,
                  placeholder: "",
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: 160,
                  height: null,
                  label: "LEI end date",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: "",
                  trailingText: "",
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: 1,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 5,
                  inputType: 1,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "arial",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "arial",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "datePicker-9acb-KYC2",
                  value: null,
                  placeholder: "",
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: 160,
                  height: null,
                  label: "Date of registration",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: "",
                  trailingText: "",
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 3,
              span: null,
              margin: "0",
              padding: "0 20px 0 0",
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            },
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 5,
                  inputType: 1,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "left",
                    fontFamily: "arial",
                    size: 16,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: {
                    level: null,
                    alignment: "left",
                    fontFamily: "arial",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "datePicker-1ac9-KYC2",
                  value: null,
                  placeholder: "",
                  borderWidth: "1px",
                  borderColor: "#000",
                  width: 160,
                  height: null,
                  label: "Under formation (UBV) start date",
                  labelSetManually: true,
                  helpText: null,
                  leadingText: "",
                  trailingText: "",
                  labelTop: true,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: null,
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: 1,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        },
        {
          id: uuidv4(),
          columns: [
            {
              id: uuidv4(),
              borderWidth: "0",
              elements: [
                {
                  id: uuidv4(),
                  type: 12,
                  inputType: 2,
                  validateData: 0,
                  country: 0,
                  tableValueType: 0,
                  dynamicListValues: null,
                  video: null,
                  richTextValues: null,
                  bitmap: null,
                  layout: {
                    order: 2,
                    right: null
                  },
                  text: {
                    level: null,
                    alignment: "center",
                    fontFamily: "sans-serif",
                    size: 14,
                    bold: false,
                    italic: false,
                    underline: false,
                    color: "#000",
                    width: 0
                  },
                  labelStyle: null,
                  dependencies: null,
                  orderTable: null,
                  map: {
                    hideDropdown: false
                  },
                  customErrorMessage: null,
                  smartTable: null,
                  smartTableHeaders: [],
                  displayLabel: null,
                  valueLabel: null,
                  property: "fileUpload-KYC2",
                  value: "",
                  placeholder: "",
                  borderWidth: "1px",
                  borderColor: "#e5e7eb",
                  width: null,
                  height: null,
                  label: "Copy of registration certificate or equivalent (protocols, status etc) is attached",
                  labelSetManually: true,
                  helpText: "",
                  leadingText: null,
                  trailingText: null,
                  labelTop: false,
                  backgroundColor: "#fff",
                  roundedCorners: false,
                  defaultCheckedState: null,
                  isButton: null,
                  margin: "30px 0 0 0",
                  processStep: 0,
                  signatureProcess: null,
                  leadingIcon: null,
                  minValue: null,
                  maxValue: null,
                  minLength: null,
                  maxLength: null,
                  rows: null,
                  apiValidationId: null,
                  apiRetrieveId: null,
                  workflowRetrieveId: null,
                  workflowRetrieveLoadId: null,
                  options: null,
                  optionWithValueAlphabeticalOrder: false,
                  optionWithValues: null,
                  flowOptions: null,
                  preventDataInsert: false,
                  maxFileSizeInMb: null,
                  readOnly: false,
                  maxRows: null,
                  connectedParentElementId: null,
                  connectedElements: null,
                  initialValue: null,
                  lockRows: false,
                  requiredField: null,
                  requiredSignatures: [],
                  hideLabel: false,
                  orderTableValue: null,
                  hasAutomaticTranslationOff: false,
                  connectedElement: null,
                  resourceId: null,
                  restrictedFileTypes: null,
                  matrix: null,
                  isDynamicElement: false
                }
              ],
              contentAlign: null,
              verticalAlign: null,
              backgroundColor: null,
              space: 0,
              span: null,
              margin: "0",
              padding: null,
              borderColor: null,
              roundedCorners: false,
              fullHeight: false
            }
          ],
          requiredRadioGroup: false,
          radioGroupName: null,
          space: 5,
          gap: null,
          backgroundColor: null,
          margin: "5px 0",
          padding: "0",
          borderWidth: "0",
          borderColor: null,
          roundedCorners: true,
          dynamicRowConfig: {
            enableRowRepeat: false,
            newRowRequiresAllFieldsToBeFilled: false
          }
        }
      ],
      showLogicControl: [],
      hasAutomaticTranslationOff: false,
      resourceId: null,
      readOnly: null
    }
  }
}