import { useState } from "react"
import { ChatAlt2Icon, TrashIcon, PencilIcon } from "@heroicons/react/outline"
import { conditionConectorTypes, conditionTypesByElementType, elementTypes } from "../../../utils/constants"
import { InputWirthElementValidationAndConfirmCancelButton } from "./matchInputWithElementTypeValidation"
import { ConditionTypeByElementTypeSelector } from "./conditionTypeByElementTypeSelector"

const LocalTrashIcon = ({ onClick }) => <TrashIcon className={"w-5 invisible group-hover:visible cursor-pointer bg-yellow-400 ml-1"} onClick={onClick} />
const LocalEditIcon = ({ onClick }) => <PencilIcon className={"w-5 invisible group-hover:visible cursor-pointer bg-yellow-400 ml-1"} onClick={onClick} />
const ConectorLabel = ({ label }) => <div className="bg-brand-background px-5 py-1 text-xs font-semibold text-gray-500 max-w-min">{label}</div>

const SavedConditionInput = ({ elementType, match }) => {
    switch (elementType) {
        case elementTypes.checkbox:
            return <input
                className="focus:ring-gray-400 focus:border-gray-400 border-gray-400 bg-white border rounded-md m-1 px-1 py-px font-semibold"
                type={"checkbox"}
                checked={match === "true"}
            />

        default:
            return match
    }
}

export const Condition = ({ element,
    containerName,
    conditionType,
    match,
    conectorLabel,
    conector,
    onRemoveConditionClick,
    setIndexEdit,
    indexEdit,
    onConfirmEditClick,
    groupedElementsOfContainer,
    condition,
    index
}) => {
    const [editableCondition, setEditableCondition] = useState(condition);

    const onSaveNewConditionClick = () => {
        const editedCondition = {
            condition: editableCondition.condition,
            conector: editableCondition.conector,
        }

        onConfirmEditClick(editedCondition)
    }

    const onConditionChange = (e) => {
        setEditableCondition({
            ...condition,
            condition: {
                ...condition.condition,
                [e.target.name]: e.target.value,
                property: initialPropertyVerification(e.target.value)
            },
        });
    };

    const initialPropertyVerification = (elementValue) => {
        if(groupedElementsOfContainer?.find(g => g.elements?.find(x => x?.value === elementValue)))
            return groupedElementsOfContainer?.find(g => g.elements?.find(x => x?.value === elementValue))?.elements?.find(p => p?.value === elementValue)?.property
        return editableCondition.condition.property
    }

    return (
        <>
            <div className="group flex gap-2 flex-1 my-1 items-center flex-wrap">
                <div className="px-1 text-sm bg-brand-background">

                    <div className="border-gray-400 bg-white border rounded-md m-1 px-1 py-px flex font-semibold text-xs">
                        <ChatAlt2Icon className="bg-green-100 text-green-700 rounded-md w-5 h-4 mr-1 p-0.5" />
                        {containerName || element?.property}
                    </div>
                </div>
                <div className="px-1 text-sm bg-brand-background">

                    {indexEdit ?
                        <ConditionTypeByElementTypeSelector elementType={element.type} condition={editableCondition} onChange={(e) => onConditionChange(e)} />
                        : <div className="border-gray-400 bg-white border rounded-md m-1 px-1 py-px flex font-semibold text-xs">
                            {conditionTypesByElementType(element.type)[conditionType]}
                        </div>}
                </div>
                {indexEdit ?
                    <InputWirthElementValidationAndConfirmCancelButton
                        onChange={onConditionChange}
                        newCondition={{ ...editableCondition, element }}
                        groupedElementsOfContainer={groupedElementsOfContainer}
                        onCancelClick={() => setIndexEdit(false)}
                        onConfirmClick={() => onSaveNewConditionClick()}
                    />
                    : <>
                        <div className="p-1 text-sm bg-brand-background flex-nowrap">
                            <SavedConditionInput elementType={element.type} match={match} />
                        </div>
                        <div className="flex">
                            <LocalEditIcon
                                onClick={() => setIndexEdit(index)}
                            />
                            <LocalTrashIcon
                                onClick={() => onRemoveConditionClick()}
                            />
                        </div>
                    </>}
            </div>
            {conector !== conditionConectorTypes.none && (
                <ConectorLabel label={conectorLabel} />
            )}
        </>
    );
};