import { useEffect, useLayoutEffect, useState } from "react";
import { conditionConectorTypes, elementTypes } from "../../../utils/constants";
import { InputWirthElementValidationAndConfirmCancelButton } from "./matchInputWithElementTypeValidation";
import { ConditionTypeByElementTypeSelector } from "./conditionTypeByElementTypeSelector";

const ElementSelector = ({ elements, onChange, groupedElementsOfContainer }) => {

    let elementsToShow = elements || [];

    if (groupedElementsOfContainer) {
        let formatedGroupElements = [];
        formatedGroupElements = groupedElementsOfContainer.reduce((acc, group) => [
            ...acc,
            {
                ...group.elements[0],
                nameToShow: group.rowGroupName ?? `Radio group-${group.containerName}`,
                containerId: group.containerId,
                rowId: group.rowId
            }
        ], []);
        elementsToShow = [...elementsToShow, ...formatedGroupElements]        
    }

    const onElementChange = (e) => {
        const element = elementsToShow.find(element => element.property === e.target.value)
        onChange(e, element)
    }

    return (
        <select
            id="border-width"
            onChange={onElementChange}
            name="property"
            className="focus:ring-gray-400 focus:border-gray-400 border-gray-400 pr-6 bg-white border rounded-md m-1 px-1 py-px font-semibold text-xs"
            defaultValue={elements?.[0]?.property}
        >
            {elementsToShow?.map((element, index) => <option key={index} value={element?.property}>{element?.nameToShow || element?.property}</option>)}
        </select>
    )
}

export const NewCondition = ({
    conectorLabel,
    elementsOfContainer,
    groupedElementsOfContainer,
    cancelNewConditionClick,
    onSaveNewCondition,
    defaultElementSelector,
    isFirstCondition }) => {

    const initialMatchVerification = (defElement) => {
        const defElementType = defElement?.type
        if (defElementType === elementTypes.checkbox) return "false"
        if (defElement?.optionWithValues) return defElement?.optionWithValues?.[0]?.value
        if (defElement?.rowId) return defElement.value

        return "";
    }

    const initialPropertyVerification = (elementValue) => {       
        if(groupedElementsOfContainer?.find(g => g.elements?.find(x => x?.value === elementValue)))
            return groupedElementsOfContainer?.find(g => g.elements?.find(x => x?.value === elementValue))?.elements?.find(p => p?.value === elementValue)?.property
        return newCondition.condition.property
    }

    const initialNewCondition = {
        condition: {
            property: defaultElementSelector?.property,
            conditionType: 0,
            match: initialMatchVerification(defaultElementSelector),
        },
        conector: 0,
        element: defaultElementSelector,
    };

    const [newCondition, setNewCondition] = useState(initialNewCondition);

    const onAddOrCancelConditionClick = (conector) => {
        setNewCondition({ ...initialNewCondition, conector });
    };

    const onConditionChange = (e) => {
        setNewCondition({
            ...newCondition,
            condition: {
                ...newCondition.condition,
                [e.target.name]: e.target.value,
                property: initialPropertyVerification(e.target.value)
            },
        });
    };

    const onElementChange = (e, element) => {
        setNewCondition({
            ...newCondition,
            condition: {
                ...newCondition.condition,
                [e.target.name]: e.target.value,
                match: initialMatchVerification(element),
                property: element.property
            },
            element,
        });
    };

    const onCancelNewConditionClick = () => {
        onAddOrCancelConditionClick(conditionConectorTypes.none);
        cancelNewConditionClick();
    };

    const onSaveNewConditionClick = (e, newCondition) => {
        if (!newCondition.condition.match) {
            return e.preventDefault();
        }
        delete newCondition.element;
        onSaveNewCondition(newCondition);
        setNewCondition(initialNewCondition);
    };

    return (
        <>
            {!isFirstCondition && (
                <div className="bg-brand-background px-5 py-1 text-xs font-semibold text-gray-500 max-w-min">
                    {conectorLabel}
                </div>
            )}
            <div className="group flex gap-2 flex-1 my-1 items-center flex-wrap">
                <div className="px-1 text-sm bg-brand-background">
                    <ElementSelector
                        elements={elementsOfContainer}
                        groupedElementsOfContainer={groupedElementsOfContainer}
                        onChange={onElementChange}
                    />

                </div>
                <ConditionTypeByElementTypeSelector condition={newCondition} elementType={newCondition?.element?.type} onChange={(e) => onConditionChange(e)} />
                <InputWirthElementValidationAndConfirmCancelButton
                    onChange={onConditionChange}
                    newCondition={newCondition}
                    groupedElementsOfContainer={groupedElementsOfContainer}
                    onCancelClick={() => onCancelNewConditionClick()}
                    onConfirmClick={(e) => onSaveNewConditionClick(e, newCondition)}
                />
            </div>
        </>
    );
}