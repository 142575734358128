import { useState, useEffect } from "react";
import { setProperty } from "../../utils/property";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Property from "../../components/Properties";
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';
import { alignmentOptions, bitmapPositionOptions } from "../../utils/constants";

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">
      <Property.Group title="Information">
        <Property.Label
          label="Type"
          value="Hyperlink"
        />

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={"Property"}
          name={"property"}
          value={data?.property}
        />

        <Property.TextArea
          onChange={labelUpdate.onChange}
          label={"Label"}
          name={"label"}
          value={data?.label}
        />

        <Property.TextArea
          onChange={handleOnChange}
          label={"Url"}
          name={"value"}
          value={data?.value}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Show hypherlink as button"}
          name={"isButton"}
          value={data?.isButton}
        />
      </Property.Group>

      <Property.Group title="Text Style">
        <Property.Color
          onChange={handleOnChange}
          label={"Color"}
          name={"text.color"}
          initColor={data?.text?.color}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Size"}
          name={"text.size"}
          type={'number'}
          value={data?.text?.size || null}
        />

        <Property.FontStyleAndWeight 
          onChange={handleOnChange}
          name="text"
          property={data?.text}
        />
        
        <Property.Select
          onChange={(e) => handleOnChange(e)}
          label={"Alignment"}
          name={"text.alignment"}
          value={data?.text?.alignment}
          options={alignmentOptions}
        />
        
      </Property.Group>

      <div className={`${!data?.isButton ? "hidden" : ""}`}>
        <Property.Group title="Button Style">
          <Property.Color
            onChange={handleOnChange}
            label={'Background color'}
            name={'backgroundColor'}
            initColor={data?.backgroundColor}
          />

          <Property.Text
            onChange={handleOnChange}
            label={'Width'}
            name={'width'}
            type={'number'}
            value={data?.width}
          />

          <Property.Checkbox
            onChange={handleOnChange}
            label={"RoundedCorners"}
            name={"roundedCorners"}
            value={data?.roundedCorners}
          />

          <Property.Color
            onChange={handleOnChange}
            label={'Border color'}
            name={'borderColor'}
            initColor={data?.borderColor}
          />

          <Property.BorderStyleAndWidth
            onChange={handleOnChange}
            label={'Border'}
            name={'borderWidth'}
            value={data?.borderWidth}
          />

          <Property.Base64Upload
            onChange={handleOnChange}
            label={'Bitmap'}
            name={'bitmap.value'}
            value={data?.bitmap?.value}
            maxFileSizeInMb={1} // matches backend UpdateDialogDefinitionDesignCommandValidator
            acceptedExtensions={"image/*"}
          />

          <Property.Text
            onChange={(e) => handleOnChange(e, 'number')}
            label={'Bitmap size'}
            name={'bitmap.width'}
            value={data?.bitmap?.width}
            type={'number'}
          />

          <Property.Select
            onChange={(e) => handleOnChange(e, 'number')}
            label={"Bitmap position"}
            name={"bitmap.position"}
            value={data?.bitmap?.position}
            options={bitmapPositionOptions}
          />

        </Property.Group>
      </div>

    </div>
  );
}
