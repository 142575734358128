/* jshint esversion: 11 */

import Modal from "../../../components/Modal";
import { Button } from "../../../components/Form/Button";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { FormikTextInput } from "../../../components/Form/Formik/FormikTextInput";
import { useToastAction } from "../../../hooks/useToastAction";
import { FormikSubmitButton } from "../../../components/Form/Formik/FormikSubmit";
import { FormikCheckbox } from "../../../components/Form/Formik/FormikCheckbox";
import { sendDialogValues } from "../../../api/dialogValues";
import { securityType, processTypes, smartFormEnvironmentNames, smartFormEnvironments } from "../../../utils/constants";
import { useContext, useEffect, useRef, useState } from "react";
import { getParticipatingParties } from "../../../api/participatingParties";
import { getCustomerIntegrations } from "../../../api/customerIntegrations";
import { FormikDate } from "../../../components/Form/Formik/FormikDate";
import { FormikPhoneInput } from "../../../components/Form/Formik/FormikPhoneInput";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { FormikSelectInput } from "../../../components/Form/Formik/FormikSelect";
import { featureFlags } from "../../../utils/features";
import idValidator from 'nordic-id-validator';
import { AdminlayoutContext, OidcRoutesContext } from "../../../contexts";
import { useTemplateLanguageActions } from "../../../hooks/useTemplateLanguageActions";

export const SendDialog = ({
  dialogDefinition,
  modalHelper,
}) => {
  const [participatingParties, setParticipatingParties] = useState([]);
  const [customerIntegrations, setCustomerIntegrations] = useState([]);
  const [existRequiredSmsAuthenticationInSteps, setExistRequiredSmsAuthenticationInSteps] = useState(false);
  const sendSmsRef = useRef();
  sendSmsRef.current = false;
  const receiverPhoneRef = useRef();
  const senderPhoneRef = useRef();
  const sendAction = useToastAction(true);
  const loadAction = useToastAction();
  const isMultiStepProcess = dialogDefinition?.process === processTypes.multiStep;
  const { userProfile: { fullName, email }, ...oidcRoutesContext } = useContext(OidcRoutesContext)
  const formikRef = useRef();
  const adminLayoutContext = useContext(AdminlayoutContext);
  const templateLanguageActions = useTemplateLanguageActions(adminLayoutContext.templateLanguages);
  const environmentOptions = Object.keys(smartFormEnvironmentNames).map(k => ({ name: smartFormEnvironmentNames[k], value: k }));

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    loadAction.execute(async () => {
      const customerIntegrations = await getCustomerIntegrations();
      setCustomerIntegrations(customerIntegrations);
      if (isMultiStepProcess) {
        const participatingPartiesSrv = await getParticipatingParties(dialogDefinition.id, { activeInSteps: true });
        let existSmsAuthentication = false;
        const mapped = participatingPartiesSrv.map(p => {
          const { name, ...rest } = p;

          if (p.securityOptions && p.securityOptions.some(so => so === securityType.phone)) {
            existSmsAuthentication = true;
          }

          return {
            ...rest,
            partyName: name,
            languageCode: rest.defaultLanguage,
            ...(customerIntegrations.availableBankIdAuthMethods ? { 
              nationality: customerIntegrations.availableBankIdAuthMethods[0]
            } : null)
          };
        });
        setExistRequiredSmsAuthenticationInSteps(existSmsAuthentication);
        setParticipatingParties(mapped);
      }
    }, "Failed to load");
  };

  const cancel = (evt) => {
    evt.preventDefault();
    modalHelper.close();
  };

  const handleSSNBlur = (e, i, props) => {
    const ssn = e.target.value;
    let y, m, d;
    if (((ssn || '') !== '') && !props.errors.parties?.[i]?.ssn) {
      const thisYear = (new Date()).getFullYear();
      switch (props.values.parties?.[i]?.nationality) {
        case 'DK':
        case 'NO':
          d = parseInt(ssn.substring(0, 2));
          m = parseInt(ssn.substring(2, 4));
          y = parseInt(ssn.substring(4, 6));
          while (y < thisYear - 110) y += 100;
          break;
        case 'SE':
          if (ssn.length === 12) {
            d = parseInt(ssn.substring(6, 8));
            m = parseInt(ssn.substring(4, 6));
            y = parseInt(ssn.substring(0, 4));
          }
          else {
            d = parseInt(ssn.substring(4, 6));
            m = parseInt(ssn.substring(2, 4));
            y = parseInt(ssn.substring(0, 2));
            while (y < thisYear - 110) y += 100;
          }
          break;
      }
      props.setFieldValue(`parties[${i}].birthDate`, new Date(Date.UTC(y, m - 1, d)));
      setTimeout(formikRef.current.validateForm, 100);
    }
  };

  const onSendSmsClick = (evt) => {
    sendSmsRef.current = evt.target.checked;
  };

  const hasBankIdSecurityOption = (securityOptions) => {
    const securityOptionsVerify = securityOptions.find(so => so === securityType.bankId);
    return isMultiStepProcess && securityOptionsVerify ? true : false;
  };

  const hasPhoneSecurityOption = (securityOptions) => {
    const securityOptionsVerify = securityOptions.some(so => so === securityType.phone);
    return isMultiStepProcess && securityOptionsVerify ? true : false;
  };

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={modalHelper.close}
      size="large"
      title="Send dialog"
      showOverflow={true}
    >
      <div>
        <Formik
          innerRef={formikRef}
          initialValues={{
            receiverName: '',
            languageCode: dialogDefinition.defaultLanguage,
            receiverEmail: '',
            receiverPhone: '',
            receiverSSN: '',
            senderName: fullName,
            senderEmail: email,
            senderPhoneNumber: '',
            privateSecret: fullName?.trim(),
            dialogValuesSecurity: securityType.none,
            sendEmail: true,
            sendSms: existRequiredSmsAuthenticationInSteps,
            isMultiStepProcess: isMultiStepProcess,
            parties: isMultiStepProcess ? participatingParties : [],
            environment: smartFormEnvironments.development
          }}
          validationSchema={() => {
            return yup.object().shape({
              receiverPhone: yup.string().when(["isMultiStepProcess", "sendSms"], {
                is: (isMultiStepProcess, sendSms) => isMultiStepProcess === false && sendSms === true,
                then: yup.string().test("validate-phone-number", function (value) {

                  const { path, createError } = this;
                  const isValid = value ? isPossiblePhoneNumber(value) : false
                  if (!isValid) {
                    return createError({ path, message: 'Receiver phone number must be valid.' });
                  }

                  return true;
                }).required("Required"),
              }),
              receiverName: yup.string().when("isMultiStepProcess", {
                is: false,
                then: yup.string().required("Required")
              }),
              languageCode:yup.string().when("isMultiStepProcess", {
                is: false,
                then: yup.string().required("Required"),
                otherwise: yup.string().nullable()
              }),
              receiverEmail: yup.string().email().when("isMultiStepProcess", {
                is: false,
                then: yup.string().required("Required")
              }),
              privateSecret: yup.string().required("Required"),
              parties: yup.array()
                .of(
                  yup.object().shape({
                    name: yup.string().required("Required"),
                    email: yup.string().email("Must be an email").required("Required"),
                    languageCode: yup.string().required("Required"),
                    phone: yup.string().when(["sendSms", "securityOptions"], {
                      is: (sendSms, securityOptions) => sendSmsRef.current === true || hasPhoneSecurityOption(securityOptions) || existRequiredSmsAuthenticationInSteps, // hasPhoneSecurityOption - need to use ref because inner yup arrays can't get values of outter object
                      then: yup.string().test("validate-phone-number", function (value) {

                        const { path, createError } = this;
                        const isValid = value ? isPossiblePhoneNumber(value) : false
                        if (!isValid) {
                          return createError({ path, message: 'Sender phone number must be valid.' })
                        }

                        return true;
                      }).required("Required")
                    }),
                    ssn: yup.string().when(["nationality"], (nationality) => {
                      return yup.string().test('is-valid-ssn', 'Invalid SSN', (ssn) => {
                        if (!ssn) return true;
                        const trimmedSSN = ssn.replace(/[^\d]/g, '');
                        if (trimmedSSN.length === 0) return true;
                        const validator = new idValidator();
                        return validator.isValid(trimmedSSN, nationality);
                      })
                    }),
                    birthDate: yup.date().when(["securityOptions"], {
                      is: (securityOptions) => hasBankIdSecurityOption(securityOptions),
                      then: yup.date().required("Required")
                    })
                  })
                )
            })
          }}
          onSubmit={async (values, actions) => {
            delete values.isMultiStepProcess;
            sendAction.execute(async () => {
              const payload = {
                dialogDefinitionId: dialogDefinition.id,
                environment: +values.environment,
                dialogValuesSecurity: parseInt(values.dialogValuesSecurity),
                sendEmail: values.sendEmail,
                sendSms: values.sendSms,
                receiverEmail: values.receiverEmail,
                receiverName: values.receiverName,
                receiverPhone: values.receiverPhone,
                receiverSSN: values.receiverSSN,
                privateSecret: values.privateSecret,
                languageCode: values.languageCode,
                participatingParties: values.parties.map(p => {
                  return (
                    {
                      participatingPartyId: p.id,
                      email: p.email,
                      name: p.name,
                      phone: p.phone,
                      nationality: p.nationality,
                      ssn: p.ssn,
                      birthDate: p.birthDate,
                      languageCode: p.languageCode
                    }
                  )
                })
              }
              await sendDialogValues(payload)
              actions.setSubmitting(false)
              modalHelper.close();
            }, "Failed to send form", "Form has been sent!", "...Sending")
          }}
        >
          {
            props => (
              <Form>
                {
                  isMultiStepProcess && participatingParties.map((p, i) => {
                    return (
                      <div className={i > 0 ? 'mt-8' : ''}>
                        <div className="border-b border-gray-300 mb-4">
                          <h3 className="text-gray-900 text-md">{p.partyName}</h3>
                        </div>
                        <div className={i > 0 ? 'mt-4 grid grid-cols-2 gap-2' : 'grid grid-cols-2 gap-2'}>
                          <FormikTextInput
                            label={`Name`}
                            name={`parties[${i}].name`}
                            required={true}
                            formikProps={props}
                          />
                          <FormikTextInput
                            label={`Email`}
                            name={`parties[${i}].email`}
                            required={true}
                            formikProps={props}
                          />
                        </div>
                        <div className="mt-4 grid grid-cols-2 gap-2">
                          {
                            (customerIntegrations.smsProviderIds.length > 0) &&
                            <FormikPhoneInput
                              label={`Phone`}
                              name={`parties[${i}].phone`}
                              required={sendSmsRef.current || hasPhoneSecurityOption(p.securityOptions)}
                              formikProps={props}
                              ref={senderPhoneRef}
                            />
                          }
                          <FormikSelectInput
                          label={`Preferred language`}
                          formikProps={props}
                          name={`parties[${i}].languageCode`}
                          options={templateLanguageActions.getAsSelectOptions()}
                          />
                        </div>
                        {
                          hasBankIdSecurityOption(p.securityOptions) &&
                          <div className="mt-4 grid grid-cols-2 gap-2">
                            <div className="flex w-full">
                              <div className="flex-none w-24 mr-2">
                                <FormikSelectInput
                                  label={`Nationality`}
                                  name={`parties[${i}].nationality`}
                                  required={true}
                                  formikProps={props}
                                  options={customerIntegrations.availableBankIdAuthMethods.map((cc) => ({ value: cc, name: cc }))}
                                  submitInChange
                                />
                              </div>
                              <div className="flex-grow">
                                <FormikTextInput
                                  label={`SSN (optional)`}
                                  name={`parties[${i}].ssn`}
                                  formikProps={props}
                                  onBlur={(e) => handleSSNBlur(e, i, props)}
                                />
                              </div>
                            </div>
                            <FormikDate
                              label={`Birth date`}
                              name={`parties[${i}].birthDate`}
                              required={true}
                              formikProps={props}
                            />
                          </div>
                        }
                      </div>
                    );
                  })
                }
                {
                  !isMultiStepProcess &&
                  <>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <FormikTextInput
                        label="Receiver name"
                        name="receiverName"
                        required={true}
                        formikProps={props}
                      />
                      <FormikSelectInput 
                      label={"Preferred language"}
                      name={"languageCode"}
                      formikProps={props}
                      options={templateLanguageActions.getAsSelectOptions()}
                      />
                    </div>

                    <div className="mt-4">
                      <FormikTextInput
                        label="Receiver email"
                        name="receiverEmail"
                        required={true}
                        formikProps={props}
                      />
                    </div>
                    {
                      (customerIntegrations.smsProviderIds.length > 0) &&
                      <div className="mt-4">
                        <FormikPhoneInput
                          label="Receiver phone number"
                          name="receiverPhone"
                          required={sendSmsRef.current}
                          formikProps={props}
                          ref={receiverPhoneRef}
                        />
                      </div>
                    }
                  </>
                }
                <div className="mt-10 pt-4 flex">
                  <FormikCheckbox
                    label="Send email"
                    name="sendEmail"
                    disabled={!props.values.sendSms}
                    formikProps={props}
                  />
                  <div className="ml-8">
                    <FormikCheckbox
                      label="Send SMS"
                      name="sendSms"
                      formikProps={props}
                      disabled={customerIntegrations.smsProviderIds.length < 1 || !props.values.sendEmail || existRequiredSmsAuthenticationInSteps}
                      title={customerIntegrations.smsProviderIds.length < 1 ? 'SMS is not set up in Centerpoint' : null}
                      onClick={onSendSmsClick}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <FormikTextInput
                    label="Private Secret (can be replaced with your own)"
                    name="privateSecret"
                    required={true}
                    formikProps={props}
                  />
                </div>
                {
                  featureFlags.smartformEnvironment && (<div className="mt-4">
                    <FormikSelectInput
                      name={'environment'}
                      label={'Environment'}
                      formikProps={props}
                      options={environmentOptions}
                    />
                  </div>)
                }
                <div className="mt-10 flex justify-end gap-2">
                  <Button
                    onClick={cancel}
                    text="Close"
                    theme="white"
                  />
                  <FormikSubmitButton
                    formikProps={props}
                    disabled={sendAction.isExecuting || !formikRef.current}
                    text={"Send"}
                  />
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </Modal>
  );
}