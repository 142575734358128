import { Field, ErrorMessage } from 'formik';
import * as _ from "lodash"
import { Label } from '../Label';
import React, { useEffect, useState, useRef } from "react";
import { DayPicker } from "react-day-picker";
import { useDateFormatter } from '../../../hooks/useDateFormatter';

export const FormikDate = ({
  name,
  formikProps,
  required = false,
  label = null,
  placeholder = null,
  readOnly = false
}) => {
  const hasError = formikProps?.errors && _.get(formikProps?.touched || {}, name) && _.get(formikProps.errors, name);
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'
  const currentYear = new Date().getFullYear();
  const fromYear = currentYear - 110;
  const toYear = currentYear;
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);
  const dateElemRef = useRef();
  const dateFormatter = useDateFormatter().dateFormatter;

  useEffect(() => {
    const date = _.get(formikProps?.values || {}, name);
    if (!isNaN(date)) {
      setSelectedDate(date);
    }
  }, [setSelectedDate, name, formikProps]);

  const onSelectDate = (date, name, formikProps) => {
    formikProps.setFieldValue(name, new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
    setShowCalendar(false);
  }
  
  const handleOnFocus = () => {
    setShowCalendar(true);
  }

  const handleOnBlur = (e) => {
    setTimeout(()=> {
      if (!dateElemRef.current.contains(document.activeElement)) {
        setShowCalendar(false);
      }
    }, 100);
  }

  return (
    <div
      ref={dateElemRef}
      onBlur={handleOnBlur}
    >
      {
        label && <Label name={name} label={label} required={required} />
      }
      <div className="mt-1">
        <Field
          type="text"
          name={name}
          className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400 ${hasError ? errorClasses : ''} ${readOnly ? 'bg-gray-200 cursor-not-allowed' : ''}`}
          placeholder={placeholder}
          readOnly={readOnly}
          autocomplete="off"
          value={selectedDate ? dateFormatter(selectedDate) : ""}
          onChange={(e) => e.preventDefault()}
          onFocus={handleOnFocus}       
        />
        <ErrorMessage name={name} component="div" className="text-red-500" />
      </div>
      {showCalendar &&
        <div className="absolute bg-brand-background rounded-sm shadow-sm z-10">
          <DayPicker
            mode="single"
            fromYear={fromYear}
            toYear={toYear}
            defaultMonth={new Date()}
            onSelect={(e) => onSelectDate(e, name, formikProps)}
            captionLayout="dropdown"
          />
        </div>
      }

    </div>
  );
}