export const featureFlags = {
  dataLoad: true,
  template: true,
  language: true,
  formsToSign: true,
  mapFromPropertyName: true,
  templateResources: true,
  templateLanguages: true || process.env.REACT_APP_FEATURE_TEMPLATELANGUAGES_ENABLED === "true",
  smartformEnvironment: false,
  pages2EngineEnabled: process.env.REACT_APP_FEATURE_PAGES2_ENGINE_ENABLED === 'true',
}


export const getDialogValueKey = (dialogValue) => {
  return featureFlags.mapFromPropertyName ? dialogValue.property : dialogValue.id
}

export const getLanguageTermKey = (languageTerm) => {
  return featureFlags.mapFromPropertyName ? languageTerm.elementProperty : languageTerm.elementId
}

export const getElementKey = (element) => {
  return featureFlags.mapFromPropertyName ? element.property : element.id
}

export const getErrorKey = (error) => {
  return featureFlags.mapFromPropertyName ? error.property : error.id;
}