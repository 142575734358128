import { useContext, useEffect, useState } from 'react'
import * as Property from "../../../../components/Properties";
import * as Data from "../../../../data";
import * as ObjectTemplates from "../../../../templates/objects";
import { DesignActionbarContext, GlobalDndContext } from '../../../../contexts';
import { ShowHideGroup } from '../../../../components/ShowHideGroup';
import { SidebarButton } from '../../../../components/SidebarButton';
import { useToastAction } from '../../../../hooks/useToastAction';
import { getAllActiveDialogObjects } from '../../../../api/dialogObjects';
import { Droppable } from 'react-beautiful-dnd';
import { DndSortable } from '../../../../components/DragAndDrop/sortable';
import { ObjectGroup } from '../../../../components/ObjectGroup';
import { SortableContext } from '@dnd-kit/sortable';
import { Columns3, AlignJustify, Heading, Text, Clock, Calendar, SquareCheck, Table, FileImage, Video, SquareArrowUpRight, Map, CircleDot, AppWindow, Rows2, AlignLeft, Link, Code, TextCursorInput, List, ToggleLeft, Signature, Sheet, Minus, NotepadText, TextSelect, Import, SquareX, Grid2X2, LifeBuoy, Rows3, Group } from 'lucide-react';
 

export default function ContentElement({ open, setOpen, finishedLoadCustomObject }) {
  const loadCustomObjectsAction = useToastAction();
  const designActionbarContext = useContext(DesignActionbarContext);
  const {
    handleAddElement,
    handleAddTemplate,
    handleAddContainer,
    handleAddRow,
    handleAddColumn,
    disableAddObjects,
    disableAddContainer,
    canAdd,
  } = designActionbarContext || {}
  const globalDndContext = useContext(GlobalDndContext)

  const allDesignObjects = [
    { type: "column", text: "Column", color: "blue", onClick: handleAddColumn, icon: <Columns3  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: "row", text: "Row", color: "blue", onClick: handleAddRow, icon: <Rows3  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: "container", text: "Section", color: "blue", onClick: handleAddContainer, icon: <Group  strokeWidth={1.5} className="h-5 w-5" /> },
  ]

  const allObjects = [
    //#region Elements
    { type: 'element', text: "Headline", toAdd: Data.HeadLineNew, icon: <Heading  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Text", toAdd: Data.TextLineNew, icon: <Text  strokeWidth={1.5} className="h-5 w-5" /> },
    // { type: 'element', text: "Textarea", toAdd: Data.TextAreaNew, icon: Icons.faBlockQuote },
    { type: 'element', text: "Hyperlink", toAdd: Data.HyperLinkNew, icon: <Link  strokeWidth={1.5} className="h-5 w-5" /> },

    { type: 'element', text: "Input Line", toAdd: Data.InputTextLine, icon: <TextCursorInput  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Input Area", toAdd: Data.InputTextArea, icon: <TextSelect  strokeWidth={1.5} className="h-5 w-5" /> },

    { type: 'element', text: "Time Picker", toAdd: Data.TimePicker, icon: <Clock  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Date Picker", toAdd: Data.DatePicker, icon: <Calendar  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Checkbox", toAdd: Data.Checkbox, icon: <SquareCheck  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Switch", toAdd: Data.Switch, icon: <ToggleLeft  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Radio List", toAdd: Data.RadioList, icon: <List  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Dropdown", toAdd: Data.Dropdown, icon: <Rows2  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Radio Button", toAdd: Data.RadioButtonGroup, icon: <CircleDot  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Order Table", toAdd: Data.OrderTableNew, icon: <Table  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Signature", toAdd: Data.SignatureNew, icon: <Signature  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Smart Table", toAdd: Data.SmartTable, icon: <Sheet  strokeWidth={1.5} className="h-5 w-5" /> }, // ADD SMART TABLE HERE
    //{ type: 'element', text: "Dynamic List", toAdd: Data.DynamicList, icon: Icons.faList, addSeparator: true },

    { type: 'element', text: "Bitmap", toAdd: Data.Bitmap, icon: <FileImage  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Resource", toAdd: Data.Resource, icon: <Code  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Video", toAdd: Data.Video, icon: <Video  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Divider", toAdd: Data.Divider, icon: <Minus  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "File Upload", toAdd: Data.FileUpload, icon: <Import  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Submit Button", toAdd: Data.Button, icon: <SquareArrowUpRight  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Clear Button", toAdd: Data.CancelButton, icon: <SquareX  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Rich Text", toAdd: Data.RichText, icon: <AlignLeft  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Attachment", toAdd: Data.Attachment, icon: <Link  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Spacer", toAdd: Data.Spacer, icon: <AlignLeft  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Matrix", toAdd: Data.Matrix, icon: <Grid2X2  strokeWidth={1.5} className="h-5 w-5" /> },
    { type: 'element', text: "Map", toAdd: Data.Map, icon: <Map  strokeWidth={1.5} className="h-5 w-5" /> },
    //#endregion
  ];

  const [objectsToAdd, setObjectsToAdd] = useState(allObjects)

  useEffect(() => {
    loadCustomObjectsAction.execute(async () => {
      const results = await getAllActiveDialogObjects()
      const objects = results.map(r => {
        return (
          {
            type: 'custom',
            text: r.name,
            icon: <LifeBuoy  strokeWidth={1.5} className="h-5 w-5" />,
            toAdd: () => r,
            color: 'purple'
          }
        );
      })
      const all = [
        ...objectsToAdd,
        ...objects,
      ]

      globalDndContext.setSidebarElements([
        { type: "element", objects: all.filter(o => o.type === "element") },
        { type: "container", objects: all.filter(o => o.type !== "element").map(a => ({ ...a, type: 'container' })) },
      ])

      setObjectsToAdd(all)
    })

    let scrollToElement = window.location.hash.replace('#', '')

    if (scrollToElement) {
      let element = document.getElementById(scrollToElement);
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    finishedLoadCustomObject()
  }, [])

  const handleSearch = (e) => {
    const searchBy = e.target.value

    if (searchBy && searchBy !== '') {
      setObjectsToAdd([...allObjects.filter(m => m.text.toLowerCase().includes(searchBy.toLowerCase()))])
    }
    else {
      setObjectsToAdd([...allObjects])
    }
  }

  const handleFilter = (e) => {
    const filterBy = e.target.value

    if (filterBy === 'all')
      setObjectsToAdd([...allObjects])
    else
      setObjectsToAdd([...allObjects.filter(m => m.type.toLowerCase() === filterBy)])
  }

  if (!canAdd()) {
    return (
      <Property.Group
        title='Locked'
      >
        <div className="ml-6 pt-2">
          This item is locked to a template
        </div>
      </Property.Group>
    )
  }

  if (loadCustomObjectsAction.isExecuting
    || !globalDndContext.getElements("element")
    || !globalDndContext.getElements("container")) {
    return null
  }

  return (
    <div id={"contentElement"}>
      <div className='h-full px-4 sm:px-6 '>
          <div className="mt-2 pb-6 border-b">
            <span className="flex-grow flex flex-col" id="availability-label">
              <span className="text-sm font-medium text-gray-500">Search</span>
            </span>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type={"text"}
                className={`focus:ring-gray-400 focus:border-gray-400 block w-full sm:text-sm border-gray-300 rounded-md`}
                onChange={handleSearch}
              />
            </div>
          </div>
          {/* <div className="mt-2 flex items-center justify-between border-b pb-4">
            <span className="flex-grow flex flex-col" id="availability-label">
              <span className="text-sm font-medium text-gray-500">Filter</span>
            </span>
            <div className="mt-1 ml-7 relative rounded-md shadow-sm">
              <select
                className={`focus:ring-gray-400 focus:border-gray-400 block w-72 sm:text-sm border-gray-300 rounded-md`}
                onChange={handleFilter}
              >
                {<option value="all">All</option>}
                {
                  Array.from(new Set(allObjects.map(({ type }) => type))).map(
                    (type, index) => <option key={index} value={type}>{type.split('_').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}</option>
                  )
                }
              </select>
            </div>
          </div> */}
        <div className="popover-container-items mb-2 element-pop-bottom">
          <ShowHideGroup
            label={`Design`}
            defaultOpen={true}
            useGrid={false}
            hideWhenEmpty={true}
          >
            <Droppable droppableId='sidebar-design' type="sidebar">
              {(droppableProvided) => (
                <div
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                  className={`flex flex-wrap`}
                >
                  {allDesignObjects?.map((element, index) => {
                    if (element.type === "container" && disableAddContainer) {
                      return false;
                    }
                    else if (element.type === "container") {
                      return (
                        <SortableContext
                          key={index}
                          id={`container-sortable-context-sidebar`}
                          items={[`container-${globalDndContext.sidebarContainerId}`]}
                        >
                          <div className='w-1/2 p-1'>
                            <DndSortable
                              type='container'
                              id={`container-${globalDndContext.sidebarContainerId}`}
                            >
                              <SidebarButton
                                text={element.text}
                                onClick={element.onClick}
                                color={element.color}
                                icon={element.icon}
                                style={{ cursor: "grab" }}
                              />
                            </DndSortable>
                          </div>
                        </SortableContext>
                      )
                    }
                    else if (element.type === "column") {
                      return (
                        <SortableContext
                          key={index}
                          id={`column-sortable-context-sidebar`}
                          items={[`column-${globalDndContext.sidebarColumnId}`]}
                        >
                          <div className='w-1/2 p-1'>
                            <DndSortable
                              type='column'
                              id={`column-${globalDndContext.sidebarColumnId}`}
                            >
                              <SidebarButton
                                text={element.text}
                                onClick={element.onClick}
                                color={element.color}
                                icon={element.icon}
                                style={{ cursor: "grab" }}
                              />
                            </DndSortable>
                          </div>
                        </SortableContext>
                      )
                    }
                    return (
                      <SortableContext
                        key={index}
                        id={`row-sortable-context-sidebar`}
                        items={[`row-${globalDndContext.sidebarRowId}`]}
                      >
                        <div className='w-1/2 p-1'>
                          <DndSortable
                            type='row'
                            id={`row-${globalDndContext.sidebarRowId}`}
                          >
                            <SidebarButton
                              text={element.text}
                              onClick={element.onClick}
                              color={element.color}
                              icon={element.icon}
                              style={{ cursor: "grab" }}
                            />
                          </DndSortable>
                        </div>
                      </SortableContext>
                    )
                  })
                  }
                  <span className={"hidden"}>{droppableProvided.placeholder}</span>
                </div>
              )}
            </Droppable>
          </ShowHideGroup>

          <div className='pb-4'>
            <SortableContext
              id='element-sortable-context-sidebar'
              items={globalDndContext.getElements("element").map(e => e.dndId)}
              >
              <ObjectGroup
                label="Elements"
                defaultOpen={true}
                handleOnClick={handleAddElement}
                objectsToAdd={objectsToAdd.filter(m => m.type === 'element')}
                useFormButton={false}
                hideWhenEmpty={true}
                useGrid={true}
                enableDragAndDrop={true}
                dndType="element"
                />
            </SortableContext>
          </div>
        </div>
      </div>
    </div>
  )
}
