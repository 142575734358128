    import { useClassNames, useToastAction } from "@metaforcelabs/metaforce-core";
import React, { useEffect, useState } from "react";
import { downloadSignedPdf, getDialogValuesSigningProvider, getHasSignedPdf } from "../../api/public/dialogValues";
import { DownloadIcon } from "@heroicons/react/outline";

export default function FormSignedBanner({ signatories, dialogValuesId }) {
    const { classNames } = useClassNames();
    const [hasSignedDocument, setHasSignedDocument] = useState(false);
    const [signingProvider, setSigningProvider] = useState();
    const loadAction = useToastAction();
    const downloadAction = useToastAction();

    useEffect(() => {
        load();

    }, []);

    const handleDownloadSignedPdf = () => {
        if (downloadAction.isExecuting) {
            return;
        }
        downloadAction.execute(async () => {
            await downloadSignedPdf(dialogValuesId, "signed-smartform.pdf");
        }, 'Download failed');
    }

    const load = () => {
        loadAction.execute(async () => {
            const [resHasSignedPdf, signingProvider] = await Promise.all([getHasSignedPdf(dialogValuesId),getDialogValuesSigningProvider(dialogValuesId)])
            setHasSignedDocument(resHasSignedPdf?.hasSignedDocument ?? false);
            setSigningProvider(signingProvider?.name);
        }, 'Failed to load signed document');
    }


    return (
        <div className="space-x-4 sm:space-x-10 bg-green-200 py-4 px-4 sm:px-6 rounded-md border border-green-500">
            <span className="flex items-center text-green-600">
            </span>
            <div className={classNames("text-green-900 text-sm grid grid grid-cols-2")}>
                <div className={classNames(signatories?.length > 0 ? "" : "flex items-center")}>
                    {
                        signatories?.length > 0 ? (
                            <div>
                                This document has been digitally signed by:
                                <ul className="">
                                    {
                                        signatories.map(s => <li className="font-semibold">{s}</li>)
                                    }
                                </ul>
                            </div>
                        ) : (
                            <div>
                                This document has been digitally signed by all parties.
                            </div>
                        )
                    }
                    {
                        hasSignedDocument && (
                            <div className="cursor-pointer text-indigo-600 font-semibold flex mt-2" onClick={handleDownloadSignedPdf}>
                                <span>Download Signed PDF</span> <DownloadIcon className="ml-2 h-4 w-4 inline" />
                            </div>
                        )
                    }
                </div>
                {
                    signingProvider && (<div className="flex justify-end items-end">
                        Signing provider: <span className="font-semibold ml-1">{signingProvider}</span>
                    </div>)
                }
            </div>
        </div>
    )
}