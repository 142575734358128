import { useState, useContext } from 'react'
import { PopoverMenu } from '../../components/PopoverMenu';
import { MenuItem } from '../../components/PopoverMenu/menuItem';
import MenuDropdown from '../../components/PopoverMenu/menuDropdown';
import { ContainerContext, DesignActionbarContext, ShowHideContext } from "../../contexts"
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { useSelector } from 'react-redux';
import { ExportContainer } from '../../screens/Admin/DialogDesign/exportContainer';

export const Actions = ({onShowPropertiesClick, container}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const designActionbarContext = useContext(DesignActionbarContext);
  const containerContext = useContext(ContainerContext);
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)
  const [exportObjectModalOpen, setExportObjectModalOpen] = useState(false)
  const [selectedContainerForExport, setSelectedContainerForExport] = useState(null)
  const [exportContainerToFile, setExportContainerToFile] = useState(false)
  const showHideContext = useContext(ShowHideContext);

  const {
    handleRemove,
    actions,
    data,
    isCurrentThingLocked,
    disableExport,
    disableAddContainer
  } = designActionbarContext

  const onExportClicked = (containerData, exportToFile = false) => {
    if (containerData) {
      setExportObjectModalOpen(true);
      setSelectedContainerForExport(containerData);
      setExportContainerToFile(exportToFile);
    }
  }

  const onDeleteClick = (e) => {
    const containerLength = dialogDefinition.containers.length
    const lastContainerId = dialogDefinition.containers?.[containerLength - 1]?.id
    const isLastContainer =  container.id === lastContainerId

    containerContext.actions.remove(dialogDefinition, container.id)
    containerContext.removePropertiesPanel();
    isLastContainer && showHideContext?.goToPrevContainer(e)
  }
  const onDuplicateClick = () => {
    containerContext.actions.duplicate(dialogDefinition, container.id, container)
  }
  const onMoveUpClick = () => {
    containerContext.actions.moveUp(dialogDefinition, container.id, container)
  }

  const onMoveDownClick = () => {
    containerContext.actions.moveDown(dialogDefinition, container.id, container)
  }

  const onShowProperties = () => {
    onShowPropertiesClick()
  }
  
  const styles = {
    fontFamily: `'Arial', sans-serif`
  }
  
  return (
    <>
      <div className='-ml-0.5 mt-1.5' style={styles}>
        <button onClick={() => setIsMenuOpen(true)}         
          className="ignore-style-change p-1 mb-1 bg-white flex items-center hover:text-gray-600 focus:outline-none border border-gray-300 rounded-md" id="headlessui-menu-button-16" type="button" aria-haspopup="true" aria-expanded="false">
          <span className="sr-only">Open options</span>
          <DotsHorizontalIcon className="h-5 w-6" aria-hidden="true" />  
        </button>
        <PopoverMenu leftPosition={true} isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} >
          <MenuItem onClick={onShowProperties}>
            Properties 
          </MenuItem>

          {
            !disableAddContainer && 
            <MenuDropdown
              label="Export section"
              items={[
                { name: 'To object library', onClick: () => onExportClicked(containerContext.currentContainer, false) },
                { name: 'To file', onClick: () => onExportClicked(containerContext.currentContainer, true) },
              ]}
            >
              Export container
            </MenuDropdown>
          }

          {
            !disableAddContainer && 
            <MenuItem onClick={onDeleteClick}>
              Delete section
            </MenuItem>
          }

          {
            !disableAddContainer && 
            <MenuItem onClick={onDuplicateClick}>
              Duplicate section
            </MenuItem>
          }

          {
            !disableAddContainer && 
            <MenuItem onClick={onMoveUpClick}>
              Move up
            </MenuItem>
          }

          {
            !disableAddContainer && 
            <MenuItem onClick={onMoveDownClick}>
              Move down
            </MenuItem>
          }
        </PopoverMenu>
      </div>

      <ExportContainer
          isOpen={exportObjectModalOpen}
          setIsOpen={setExportObjectModalOpen}
          containerData={selectedContainerForExport}
          exportToFile={exportContainerToFile}
      />
      
    </>
  );
}