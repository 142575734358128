import { useState, useEffect, useContext, useRef } from 'react'
import { useClassNames } from '../../../hooks/useClassNames';
import ContentElement from './Tabs/contentElement';
import Theme from './Tabs/theme';
import Actions from './Tabs/actions';
import { DesignActionbarContext } from '../../../contexts';
import { debounce } from 'lodash';
import ContentObjects from './Tabs/contentObjects';
import React from 'react';
import { ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { sidebarLeftTabs, sidebarRightTabs } from '../../../utils/constants';
import { PencilRuler } from 'lucide-react';

export default function SidebarRight({ activeTab, setActiveTab, propertiesToShow, schemaErrors, data, dialogDefinition, setIsMobileVisible, isMobileVisible, disabled = false, }) {

  const designActionbarContext = useContext(DesignActionbarContext);
  const {
    disableSettings
  } = designActionbarContext || {}

  let obj = {};
  obj[data?.id] = propertiesToShow
  const [properties, setProperties] = useState(obj)
  const classNames = useClassNames();
  const posicionRef = useRef(null);
  const [addComponentScrollPosition, setAddComponentScrollPosition] = useState(0)

  const handleScroll = debounce((e) => {
    const currentElementId = posicionRef.current.firstChild?.id;
    if (activeTab === sidebarRightTabs.items && currentElementId === "contentElement") {
      setAddComponentScrollPosition(posicionRef.current.scrollTop);
    }
  }, 100)

  useEffect(() => {
    /**
     * For some reason React wasn't re-rendering children propertiesToShow even though they were changing, and it only happened when clicking between
     * two of the same element types or rows/columns. I confirmed that 'propertiesToShow' was updating and so was 'data', but for some reason it wouldn't
     * render the changes. 
     * Made this "hack" to render from state based off of 'data.id' and it seems to work.
     * Really not sure why it wasn't just working as expected.
     */
    let obj = {};
    obj[data?.id] = propertiesToShow
    setProperties({ ...obj })
  }, [propertiesToShow])

  // console.log('SCHEMA ERRORS', schemaErrors);

  const tabs = [
    { name: sidebarRightTabs.items, href: '#', current: activeTab === sidebarRightTabs.items, tab: sidebarRightTabs.items },
    { name: sidebarRightTabs.objects, href: '#', current: activeTab === sidebarRightTabs.objects, tab: sidebarRightTabs.objects },
    { name: sidebarRightTabs.properties, href: '#', current: activeTab === sidebarRightTabs.properties, tab: sidebarRightTabs.properties },
    { name: sidebarRightTabs.theme, href: '#', current: activeTab === sidebarRightTabs.theme, tab: sidebarRightTabs.theme, disabled: disableSettings },
  ]

  const propertiesEmptyState = () => {
    return (
      <div className='h-full px-4 sm:px-6 '>
        No properties to show. <br />
        Please pick an element, row, column or section.
      </div>
    )
  }

  const contentElementToSavedScrolling = () => {
    posicionRef.current.scrollTo({
      top: addComponentScrollPosition,
      behavior: "smooth",
    })
  }

  const renderActiveTab = () => {
    switch (activeTab) {
      case sidebarRightTabs.properties:
        const renderedProps = properties[data?.id] ? properties[data?.id] : propertiesEmptyState()       
        return renderedProps;
      case sidebarRightTabs.items:
        return <ContentElement finishedLoadCustomObject={contentElementToSavedScrolling} />
      case sidebarRightTabs.objects:
        return <ContentObjects finishedLoadCustomObject={contentElementToSavedScrolling} />
      case sidebarRightTabs.theme:
        return <Theme dialogDefinition={dialogDefinition} />

      default:
        return <></>;
    }
  };

  return (
    <div className={classNames.classNames(isMobileVisible ? "" : "hidden", "relative xl:block w-full h-full")}>
      <div>
        <button
          className="-left-10 py-4 mt-1.5 bg-gray-50 p-3 rounded-l-lg absolute xl:hidden shadow-left-bottom"
          type="button"
          onClick={()=>{setIsMobileVisible(false)}}
        >
          <ChevronDoubleRightIcon className='h-4' />
        </button>
      </div>
      <div className="h-full flex flex-col bg-gray-50 shadow-xl">
        <div className=' w-full border-b'>
          <div className="block">
            <nav className="flex space-x-2 max-w-full overflow-x-auto p-4" aria-label="Tabs">
              {tabs.filter(m => !m.disabled).map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={() => setActiveTab(tab.tab)}
                  className={classNames.classNames(
                    tab.current ? 'bg-gray-200 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'px-3 py-1 font-medium text-sm rounded-md'
                  )}
                  aria-current={tab.current ? sidebarLeftTabs.page : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        {
          schemaErrors?.length > 0 &&
          <div className="px-4 sm:px-6 text-red-500">
            Invalid input. Cannot save design.
          </div>
        }
        <div ref={posicionRef} onScroll={handleScroll} className="py-4 flex-1 overflow-y-scroll">
          {!disabled && renderActiveTab()}
        </div>
      </div>
    </div>
  )
}

export const MobileRightSidebarToggle = ({ onSidebarToggle }) => {

  return (
    <aside className="xl:hidden lg:-right-8 top-16 -right-6 absolute z-50">
      <button
        type="button"
        className="bg-gray-50 border border-gray-300 p-2 py-4 shadow-md"
        onClick={onSidebarToggle}
      >
        <PencilRuler className="h-4 w-4" />
      </button>
    </aside>
  )
}