import { useState, useEffect, useRef } from 'react';
import * as Property from "../../components/Properties";
import { setProperty } from '../../utils/property';
import OptionsPopup from '../optionsPopup';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePropertyToLabel } from '../usePropertyToLabel';
import { useLabelUpdate } from '../useLabelUpdate';

export default function Properties({ initData, actions }) {
  const [data, setData] = useState();
  const requiredFieldRef = useRef()
  const readOnlyRef = useRef()
  useEffect(() => {
    setData(_.cloneDeep(initData))
  }, [initData])
  const [openPopup, setOpenPopup] = useState(false)
  const dialogDefinition = useSelector((state) => state.dialogDefinitions.current)

  const propertyToLabel = usePropertyToLabel(data, setData, actions.handleChanges)
  const labelUpdate = useLabelUpdate(data, setData, actions.handleChanges)

  const handleOnChange = (e, type) => {
    const { name, value } = e.target;
    actions.handleChange(dialogDefinition, e, data.id, type)

    setProperty(data, name, value, type)
    setData(data => ({
      ...data
    }));
  }

  const handleOnChanges = (e, type) => {
    const { name: optionWithValueAlphabeticalOrderName, value: optionWithValueAlphabeticalValue } = e[0].target;
    const { name: optionWithValuesName, value: optionWithValuesValue } = e[1].target
    actions.handleChanges(dialogDefinition, e, data.id, type)
    setProperty(data, optionWithValueAlphabeticalOrderName, optionWithValueAlphabeticalValue, type)
    setProperty(data, optionWithValuesName, optionWithValuesValue)
    setData(data => ({
      ...data
    }));
  }

  return (
    <div className="popover-container-items">
      <Property.Group title="Information">
        <Property.Label
          label="Type"
          value="Radio List"
        />

        <Property.Text
          onChange={propertyToLabel.onChange}
          label={"Property"}
          name={"property"}
          value={data?.property}
        />

        <Property.Text
          onChange={labelUpdate.onChange}
          label={"Label"}
          name={"label"}
          value={data?.label}
        />

        <Property.Text
          onChange={handleOnChange}
          label={"Helper text"}
          name={"helpText"}
          value={data?.helpText}
        />

        <Property.ButtonWithLabel
          label="Items"
          onClick={() => setOpenPopup(true)}
          text='Edit items'
        />

        <Property.RequiredWithCustomMessage
          onChange={handleOnChange}
          data={data}
          ref={requiredFieldRef}
          dependency={readOnlyRef}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Read only"}
          name={"readOnly"}
          value={data?.readOnly}
          ref={readOnlyRef}
          dependency={requiredFieldRef}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={"Prevent data insert"}
          name={"preventDataInsert"}
          value={data?.preventDataInsert}
        />
      </Property.Group>

      <Property.Group title='Element Style'>

        <Property.Color
          onChange={handleOnChange}
          label={'Border color'}
          name={'borderColor'}
          initColor={data?.borderColor}
        />

        <Property.BorderStyleAndWidth
          onChange={handleOnChange}
          label={'Border'}
          name={'borderWidth'}
          value={data?.borderWidth}
        />

        <Property.Checkbox
          onChange={handleOnChange}
          label={'Rounded corners'}
          name={'roundedCorners'}
          value={data?.roundedCorners}
        />

      </Property.Group>

      <Property.Group title='Text Style'>

        <Property.Subgroup 
          title="Label">
        
          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'text.color'}
            initColor={data?.text?.color}
          />
        
          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'text.size'}
            type={'number'}
            value={data?.text?.size || null}
          />

          <Property.FontStyleAndWeight 
            onChange={handleOnChange}
            name="text"
            property={data?.text}
          />

        </Property.Subgroup>
        

        <Property.Subgroup
          title="Description">

          <Property.Color
            onChange={handleOnChange}
            label={'Color'}
            name={'labelStyle.color'}
            initColor={data?.labelStyle?.color}
          />
        
          <Property.Text
            onChange={handleOnChange}
            label={'Size'}
            name={'labelStyle.size'}
            type={'number'}
            value={data?.labelStyle?.size || null}
          />

          <Property.FontStyleAndWeight 
            onChange={handleOnChange}
            name="labelStyle"
            property={data?.labelStyle}
          />

        </Property.Subgroup>
      </Property.Group>

      <OptionsPopup addDescription={true} data={data} onChange={handleOnChange} open={openPopup} setOpen={setOpenPopup} onChanges={handleOnChanges} />
    </div>
  )
}
