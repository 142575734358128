import { useClassNames } from "@metaforcelabs/metaforce-core";
import React from "react";
import { useState } from "react";
import { sidebarLeftTabs } from "../../../utils/constants";

export default function ProcessTabs({ children, tabs, defaultTab, indentTabs = false }) {
    const [currentTab, setCurrentTab] = useState(defaultTab);

    const { classNames } = useClassNames();

    const handleSelectTab = (tab) => {
        setCurrentTab(tab.key);
    }

    return <div>

        <div className="flex mb-4">

            <div className="flex-col flex-2" style={{ minWidth: indentTabs ? "20rem" :"" }}>

            </div>
            <div className="flex flex-1 flex-col">
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        onChange={({ target }) => {
                            setCurrentTab(target.value);
                        }}
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        defaultValue={tabs.find((tab) => tab.key === currentTab).key}
                    >
                        {tabs.map((tab) => (
                            <option key={tab.key} value={tab.key}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="flex space-x-4" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <button
                                key={tab.name}
                                className={classNames(
                                    tab.key === currentTab ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800',
                                    'rounded-md px-3 py-2 text-sm font-medium'
                                )}
                                aria-current={tab.key === currentTab ? sidebarLeftTabs.page : undefined}
                                onClick={() => { handleSelectTab(tab) }}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>

        </div>

        {children({ currentTab })}
    </div>
}