export const useDateFormatter = () => {
  return {
    dateFormatter: (date) => {
      const parsedDate = new Date(date);

      if (isNaN(parsedDate.getTime())) return "Invalid Date";

      const day = String(parsedDate.getUTCDate() + 1).padStart(2, "0");
      const month = String(parsedDate.getUTCMonth() + 1).padStart(2, "0");
      const year = parsedDate.getUTCFullYear();

      return `${day}/${month}/${year}`;
      //return `${month} ${day}, ${year}`;
    },
  };
};